import React, { useEffect, useRef, useState } from "react";
import { Col, Label, Modal, ModalBody, ModalHeader, Row, Form, Input, ModalFooter, FormFeedback, Button, Spinner } from 'reactstrap';
import * as Yup from "yup";
import { useFormik } from "formik";
import { CREATE_EMPLOYEE, EMPLOYEE_CREATE_EMPLOYEE, POST_GET_INDUSTRY_TYPE } from "../../../helpers/url_helper";
import { APIClient } from "../../../helpers/api_helper";
import Toast from "../../../Components/Common/Toast";
import { ToastContainer } from "react-toastify";
import { HandleErrorMessage } from "../../../Components/Common/HandleErrorMessage";
import { FlatDatepickr } from "../../../Components/Common/Flatpicker";
import moment from "moment";
import { useSelector } from "react-redux";
const api = new APIClient();


const CreateUser = ({ createmodal_grid, GetCreateData, setCreatemodal_grid, createPermission, loginType }) => {
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState("");
    const [industryTypeData, setIndustryTypeData] = useState();
    const phoneNumberRules = /^[1-9]\d{9}$/;
    const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const storedValue = JSON.parse(localStorage.getItem("logintype"));

    const [searchTerm, setSearchTerm] = useState("");
    const [focusedIndex, setFocusedIndex] = useState(0);
    const [showDropdown, setShowDropdown] = useState(false);


    const filteredCountryList = industryTypeData?.countryList.filter((ele) =>
        ele?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ele?.code.toLowerCase().includes(searchTerm.toLowerCase())
    );


    useEffect(() => {
        if (storedValue === "organization") {
            fetchUserData();
        }

    }, []);



    const fetchUserData = async () => {
        try {
            if (storedValue === "organization") {
                const res = await api.get(`${POST_GET_INDUSTRY_TYPE}`);
                if (res.success === true) {
                    setIndustryTypeData(res?.data);
                }
            }
        } catch (error) {
            console.error("Error fetching industry types:", error);
            // Handle error as needed
        }
    };



    const formiks = useFormik({
        enableReinitialize: true,
        initialValues: {
            username: "",
            email: "",
            address: "",
            jobTitle: "",
            role: "",
            countryCode: "",
            mobileNumber: "",
            employeeId: "",
            department: "",
            dateOfJoining: "",
            designation: ""
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .matches(/^[A-Za-z\s]*$/, 'Only letters and spaces are allowed')
                .required('Please enter your Name')
                .max(16, 'Name must not be more than 16 characters'),
            email: Yup.string().email("Please enter a valid email address").matches(emailRules, { message: "  Invalid email" }).required("Please enter your email"),
            mobileNumber: Yup.string().required("Please enter your mobile no"),
            dateOfJoining: Yup.string().required(" Please enter your Registered date"),
        }),
        onSubmit: async (value) => {
            try {
                setLoading(true);
                let data = {
                    fullName: value.username,
                    employeeId: value.employeeId,
                    jobTitle: value.designation,
                    Department: value.department,
                    email: value.email,
                    mobileNumber: value.mobileNumber,
                    address: value.address,
                    countryCode: code,
                    // role: [value.role],
                    team: value.assignTeam,
                    status: "active",
                    dateOfJoining: moment(value.dateOfJoining).format("YYYY-MM-DD"),
                };

                let response;
                if (loginType === "employee" && createPermission) {
                    response = await api.create(`${EMPLOYEE_CREATE_EMPLOYEE}`, data);
                } else if (loginType === "organization") {
                    response = await api.create(`${CREATE_EMPLOYEE}`, data);
                }
                if (response.success === true) {
                    Toast(response?.message, "success");
                    setTimeout(() => {
                        GetCreateData()
                        setCreatemodal_grid(false)
                        formiks.resetForm();
                    }, 2000)
                }
            } catch (error) {
                HandleErrorMessage(error);
            } finally {
                setLoading(false);
            }
        }
    });


    const createtog_grid = () => {
        setCreatemodal_grid(!createmodal_grid);
    }

    const handleSelect = (code) => {
        setCode(code); // Set selected code
        setShowDropdown(false); // Close dropdown
        setFocusedIndex(0);
    };


    const handleKeyDown = (event) => {
        if (!showDropdown) return;

        switch (event.key) {
            case "ArrowDown":
                event.preventDefault();
                setFocusedIndex((prev) =>
                    prev < filteredCountryList.length - 1 ? prev + 1 : prev
                );
                break;
            case "ArrowUp":
                event.preventDefault();
                setFocusedIndex((prev) => (prev > 0 ? prev - 1 : prev));
                break;
            case "Enter":
                if (filteredCountryList[focusedIndex]) {
                    handleSelect(filteredCountryList[focusedIndex].code);
                }
                break;
            case "Escape":
                setShowDropdown(false);
                break;
            default:
                break;
        }
    };


    //     const handleClickOutside = (event) => {
    //         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //             setShowDropdown(false);
    //         }
    //     };

    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, []);
    return (
        <Row>
            <ToastContainer />
            <Modal
                isOpen={createmodal_grid}
                toggle={() => {
                    createtog_grid();
                }}>
                <ModalHeader className="p-3 bg-soft-success" id="exampleModalLabel" toggle={() => { createtog_grid(); }}> Add Employee </ModalHeader>
                <Form
                    onSubmit={formiks.handleSubmit}
                    className="needs-validation">
                    <ModalBody>
                        <div className="mb-0">
                            <Row>
                                <Col sm={6}>
                                    <Label htmlFor="username" className="form-label">Name<span className="text-danger">*</span></Label>
                                    <Input
                                        name="username"
                                        type="text"
                                        placeholder="Enter Name"
                                        onChange={formiks.handleChange}
                                        onBlur={formiks.handleBlur}
                                        value={formiks.values.username}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Remove non-letter and non-space characters
                                        }}
                                        invalid={
                                            formiks.touched.username && formiks.errors.username ? true : false
                                        }
                                    />
                                    {formiks.touched.username && formiks.errors.username ? (
                                        <FormFeedback type="invalid"><div>{formiks.errors.username}</div></FormFeedback>
                                    ) : null}
                                </Col>
                                <Col sm={6}>
                                    <Label htmlFor="useremail" className="form-label">Email <span className="text-danger">*</span></Label>
                                    <Input
                                        name="email"
                                        type="email"
                                        placeholder="Enter Email "
                                        onChange={formiks.handleChange}
                                        onBlur={formiks.handleBlur}
                                        value={formiks.values.email}
                                        invalid={
                                            formiks.touched.email && formiks.errors.email ? true : false
                                        }
                                    />
                                    {formiks.touched.email && formiks.errors.email ? (
                                        <FormFeedback type="invalid"><div>{formiks.errors.email}</div></FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                        </div>
                        <div className="mt-2">
                            <Row>

                                <Col className="sn-custom-country-code-main--">
                                    {/* <Label
                                        htmlFor="zipcodeInput"
                                        className="form-label">
                                        Select Country Code
                                    </Label> */}

                                </Col>


                                <Col sm={12}>
                                    <div className="sn-sales-ninja-country-input-mm">
                                        <div className="sn-sales-ninja-mobile-custom-input-sn">
                                            <Label
                                                htmlFor="zipcodeInput"
                                                className="form-label">
                                                Select Country
                                            </Label>
                                            <div
                                                className="custom-select-container"
                                                style={{ position: "relative" }}
                                                // ref={dropdownRef}
                                                tabIndex="0"
                                                onKeyDown={handleKeyDown}>
                                                <div
                                                    className="form-select sn-profile-select-country-cd"
                                                    onClick={() => setShowDropdown((prev) => !prev)}
                                                    style={{
                                                        border: "1px solid #ccc",
                                                        borderRadius: "4px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    {code || "+91"}
                                                </div>
                                                {showDropdown && (
                                                    <div
                                                        className="select-dropdown"
                                                        style={{
                                                            position: "absolute",
                                                            top: "100%",
                                                            left: "0",
                                                            right: "0",
                                                            background: "#fff",
                                                            border: "1px solid #ccc",
                                                            borderRadius: "4px",
                                                            zIndex: 10,
                                                        }}
                                                    >
                                                        <input
                                                            className="search-input"
                                                            type="text"
                                                            placeholder="Search Country Name"
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                            style={{
                                                                width: "100%",
                                                                boxSizing: "border-box",
                                                                borderBottom: "1px solid #ccc",
                                                            }}
                                                        />
                                                        <ul
                                                            className="all-coutry-code"
                                                            style={{
                                                                listStyle: "none",
                                                                margin: 0,
                                                                padding: 0,
                                                                maxHeight: "150px",
                                                                overflowY: "auto",
                                                            }}
                                                        >
                                                            {filteredCountryList.map((ele, index) => (
                                                                <li
                                                                    key={index}
                                                                    onClick={() => handleSelect(ele?.code)}
                                                                    style={{
                                                                        padding: '8px',
                                                                        cursor: 'pointer',
                                                                        backgroundColor:
                                                                            ele?.code === code
                                                                                ? '#d1ecf1'
                                                                                : index === focusedIndex
                                                                                    ? '#d1ecf1'
                                                                                    : 'transparent',
                                                                        color:
                                                                            ele?.code === code
                                                                                ? '#0c5460'
                                                                                : index === focusedIndex
                                                                                    ? '#0c5460'
                                                                                    : '#000',
                                                                    }}
                                                                    onMouseEnter={() => setFocusedIndex(index)}
                                                                >
                                                                    {ele?.code} {ele?.name}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="input-custom-country-number-sn">
                                            <Label htmlFor="mobileNumber" className="form-label">Contact Number<span className="text-danger">*</span></Label>
                                            <Input
                                                className="sn-custom-country-input"
                                                name="mobileNumber"
                                                type="tel"
                                                placeholder="Enter Mobile No."
                                                onChange={formiks.handleChange}
                                                onBlur={formiks.handleBlur}
                                                value={formiks.values.mobileNumber}
                                                invalid={
                                                    formiks.touched.mobileNumber && formiks.errors.mobileNumber ? true : false
                                                }
                                            />
                                            {formiks.touched.mobileNumber && formiks.errors.mobileNumber ? (
                                                <FormFeedback type="invalid"><div>{formiks.errors.mobileNumber}</div></FormFeedback>
                                            ) : null}
                                        </div>

                                    </div>
                                </Col>

                            </Row>
                        </div>
                        <div className="mt-2">
                            <Row>
                                <Col sm={6}>
                                    <Label htmlFor="dateOfJoining" className="form-label">Joining Date<span className="text-danger">*</span></Label>
                                    <FlatDatepickr
                                        name="dateOfJoining"
                                        value={formiks.values.dateOfJoining}
                                        onChange={formiks.setFieldValue}
                                        error={formiks.errors.dateOfJoining}
                                        touched={formiks.touched.dateOfJoining}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Label htmlFor="designation" className="form-label">Designation</Label>
                                    <Input
                                        name="designation"
                                        type="text"
                                        placeholder="Enter designation"
                                        onChange={formiks.handleChange}
                                        onBlur={formiks.handleBlur}
                                        value={formiks.values.designation}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Remove non-letter and non-space characters
                                        }}
                                        invalid={
                                            formiks.touched.designation && formiks.errors.designation ? true : false
                                        }
                                    />
                                    {formiks.touched.designation && formiks.errors.designation ? (
                                        <FormFeedback type="invalid"><div>{formiks.errors.designation}</div></FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <Button d="add-btn" color="primary" disabled={loading ? true : false} className="btn btn-success w-100" type="submit">
                                {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                Create
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </Row>
    )
}

export default CreateUser