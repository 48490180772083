import React, { useState } from "react";
import { Col, Button, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TeamSelect from "../../Components/Common/TeamSelect";
import EmployeeSelect from "../../Components/Common/EmployeeSelect";
import SelectDatePicker from "./DashboardAllFillter/selectDatePicker";
const DashboardFillter = ({
  EmployeeDefault,
  setEmployeeDefault,
  handleClickClear,
  onShowData,
  selectedDates,
  setSelectedDates,
  setSelectedEmployeeOptions,
  teamIndu,
  setTeamIndu,
  selectedEmployeeOptions,
  error,
  setError,
  setEmployeeDefaultWithoutTeam,
  EmployeeDefaultWithoutTeam
}) => {
  const storedValue = JSON.parse(localStorage.getItem("logintype"));
  const permissionType = JSON.parse(localStorage.getItem("permission"));
  const handleError = () => {
    let logintype = localStorage.getItem("logintype");
    logintype = JSON.parse(logintype);
    if (logintype === "organization") {
      if (selectedEmployeeOptions.length === 0) {
        setError("You must select at least one employee.");
      } else {
        setError("");
        onShowData();
      }
    }
    else if (logintype === "employee") {
      onShowData();
    }
  };
  const MyComponent = () => {
    const [fromDate, setFromDate] = useState(null); // stores the "from date"
    const [selectedToDate, setSelectedToDate] = useState(null); // stores the "to date"
    const [error, setError] = useState(''); // stores any validation error
 
    // Function to handle "from date" change
    const handleFromDateChange = (dateStr) => {
      setFromDate(dateStr);
      setError(''); // Reset error when "from date" changes
      setSelectedToDate(null); // Optionally reset "to date" when "from date" is updated
    };
    // Function to handle "to date" change with validation
    const handleToDateChange = (dateStr) => {
      if (fromDate) {
        const from = new Date(fromDate);
        const to = new Date(dateStr);
        const diffInMonths = (to.getFullYear() - from.getFullYear()) * 12 + to.getMonth() - from.getMonth();
        if (diffInMonths > 3) {
          setError('To date must be within 3 months of From date.');
          setSelectedToDate(null); // Optionally reset the "to date" if validation fails
        } else {
          setError('');
          setSelectedToDate(dateStr);
        }
      } else {
        setError('Please select a From date first.');
      }
    };
  }
  const handleEmployeeChange = (newSelectedEmployees) => {
    setSelectedEmployeeOptions(newSelectedEmployees);
    if (newSelectedEmployees.length > 0) {
      setError(""); // Clear the error if an employee is selected
    }
  };
  return (
    <React.Fragment>
      <Row>
        <BreadCrumb title="DASHBOARD" pageTitle="Dashboard" />
        <div className="mt-2 mt-lg-0 sn-top-inputs-heading sn-dashboard-page-home">
          <Row className="align-items-center">
            {/* {storedValue === 'employee' && permissionType.length === 0 ? <div className="col-md-3 col-sm-12">
              <div className="col-sm-auto">
                <div className="flex-grow-1 mb-4">
                  <h3
                    className="fs-19 mb-1"
                    style={{ textTransform: "capitalize" }}
                  >
                    Hello! {employeeProfileData?.fullName}  
                  </h3>
                  <h6>Here's what's happening with your store today.</h6>
                </div>
              </div>
            </div> : ''} */}
            {storedValue === "employee" && permissionType.length === 0 ? (
              <div className="col-md-3 col-sm-12">
                <div className="col-sm-auto"></div>
              </div>
            ) : (
              <div className="col-md-3 col-sm-12">
                <div className="col-sm-auto">
                  <TeamSelect
                    setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                    teamIndu={teamIndu}
                    setTeamIndu={setTeamIndu}
                    labelContent="Select Team"
                  />
                </div>
              </div>
            )}
            {storedValue === "employee" && permissionType.length === 0 ? (
              <div className="col-md-6 col-sm-12">
                <div className="col-sm-auto"></div>
              </div>
            ) : (
              <div className="col-md-3 col-sm-12">
                <div className="col-sm-auto">
                  <EmployeeSelect
                    error={error}
                    setError={setError}
                    EmployeeDefault={teamIndu.length === 0?EmployeeDefaultWithoutTeam:EmployeeDefault}
                    setEmployeeDefault={setEmployeeDefault}
                    labelContent="Select Employee"
                    teamIndu={teamIndu}
                    selectedEmployeeOptions={selectedEmployeeOptions}
                    setSelectedEmployeeOptions={handleEmployeeChange} // Pass the new handler
                    EmployeeDefaultWithoutTeam={EmployeeDefaultWithoutTeam}
                    setEmployeeDefaultWithoutTeam={setEmployeeDefaultWithoutTeam}
                  />
                </div>
              </div>
            )}
            <div
              className="col-md-6 col-sm-12 col-xs-12 sn-dasboard-datepicker-btn-main"
              style={{
                marginBottom: storedValue === "employee" ? "10px" : "",
              }}
            >
              <div className="col-sm-auto d-flex ">
                <SelectDatePicker
                  className="border"
                  selectedDates={selectedDates}
                  setSelectedDates={setSelectedDates}
                />
                <Button
                  type="button"
                  className="btn btn-danger ms-2 d-flex sn-filter-show-btn-top"
                  onClick={handleError}
                >
                  Show
                </Button>
                {storedValue === "employee" && permissionType.length === 0 ? '' : (
                  <Button
                    type="button"
                    className="btn btn-soft-danger ms-2 d-flex sn-refresh-btn"
                    onClick={handleClickClear}
                    style={{ height: '37px', width: '50px', marginTop: '5px' }}>
                    <i className="ri-refresh-line align-bottom"></i>
                  </Button>
                )}
              </div>
            </div>
          </Row>
        </div>
      </Row>
    </React.Fragment>
  );
};
export { DashboardFillter };