import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";

const TopPerformerCard = ({
  title,
  tooltipId,
  tooltipText,
  tooltipState,
  toggleTooltip,
  displayName,
  count,
  profileImage,
  rankDetails,
  actionLink,
  actionHandler,
}) => {
  const cardContent = (
    <div
      className="card-animate card sn-top-performance-card-new-design"
      style={{
        minHeight: "100px",
        maxHeight: "200px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="d-flex align-items-center">
        <div className="flex-grow-1 overflow-hidden">
          <p className="text-uppercase fw-medium text-muted text-center text-truncate mt-2 mb-0 top-caller-title-top">
            {title}
            <span
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              id={tooltipId}
              className="ri-information-line sn-dashboard-tble-toll"
            ></span>
            <div>
              <Tooltip
                placement="top"
                isOpen={tooltipState}
                autohide={false}
                target={tooltipId}
                toggle={toggleTooltip}
                style={{ maxWidth: "300px" }}
              >
                {tooltipText}
              </Tooltip>
            </div>
          </p>
        </div>
      </div>
      <div className="avatar-sm flex-shrink-0 total-connected-call-first-people mb-1 sn-top-performancename-photo">
        <img
          src={profileImage}
          alt="Profile"
          style={{
            width: "40px",
            height: "40px",
            objectFit: "cover",
            borderRadius: "50%",
            marginBottom: "8px",
          }}
        />
        <div className="flex-shrink-0 sn-performance-name-call-total">
          <h5 className="mb-0 text-black">{displayName}</h5>
          <h6 className="fw-semibold ff-secondary mb-4">
            <span>{count}</span>
          </h6>
        </div>
      </div>
      <div
        className="card-body"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingLeft: "0",
          paddingRight: "0",
        }}
      >
        <div className="mt-4">
          {!actionLink && (
            <div className="sn-employess-start-month">{rankDetails}</div>
          )}
        </div>
      </div>
    </div>
  );

  return actionLink ? (
    <a
      className="link-secondary"
      href="javascript:;"
      onClick={actionHandler}
      style={{ textDecoration: "none" }}
    >
      {cardContent}
    </a>
  ) : (
    <div>{cardContent}</div>
  );
};

TopPerformerCard.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipId: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  tooltipState: PropTypes.bool.isRequired,
  toggleTooltip: PropTypes.func.isRequired,
  displayName: PropTypes.string.isRequired,
  count: PropTypes.string.isRequired,
  profileImage: PropTypes.string.isRequired,
  rankDetails: PropTypes.node,
  actionLink: PropTypes.bool,
  actionHandler: PropTypes.func,
};

export default TopPerformerCard;
