import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Container, Input, Label, Form, FormFeedback, UncontrolledAlert, Button, Spinner } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMasterApi } from "../../Components/Hooks/UserHooks";
import { ToastContainer, toast } from 'react-toastify';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { POST_CITIES, POST_GET_INDUSTRY_TYPE, POST_PINCODE, POST_REGISTER } from "../../helpers/url_helper";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import { APIClient } from "../../helpers/api_helper";
import Toast from "../../Components/Common/Toast";
import logoheader from "../../assets/images/logo-main-white.png";
// import { Footer } from "react-bootstrap/lib/Modal";
import Footer from '../../Layouts/Footer';
const api = new APIClient();


const Register = () => {
    const { masterApiData } = useMasterApi()
    // const [getState, setGetState] = useState([])
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const phoneNumberRules = /^[1-9]\d{9}$/;
    const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [industryTypeData, setIndustryTypeData] = useState()
    const [getCity, setGetCity] = useState([])
    const [postalCode, setPostalCode] = useState([])

    // .matches(phoneNumberRules, { message: "Contact Number is Invalid" })

    
    const { handleSubmit, errors, touched, getFieldProps } = useFormik({
        enableReinitialize: true,
        initialValues: {
            username: "",
            email: "",
            postalcode: "",
            mobileNumber: "",
            city: "",
            website: "",
            countrycode: `${industryTypeData?.countryList[0]?.code},${industryTypeData?.countryList[0]?.name}` || '',
            industryType: '',
            address: '',
            stateCode: '',
            terms: false
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Name is required").max(250, "Name must not be more than 250 characters"),
            email: Yup.string().email("Please enter a valid email address").matches(emailRules, { message: "  Invalid email" }).required("Email Address is required"),
            postalcode: Yup.string().min(6, 'Should be 6 digits long').required("Postal code is required").max(6, "should not exceed 6 digits"),
            city: Yup.string().required("City Name is required"),
            mobileNumber: Yup.string().required("Contact Number is required"),
            terms: Yup.boolean().isTrue('Please read and accept the Terms of Service & Privacy Policy before continuing.'),
            countrycode: Yup.string().required('Country Name is required'),
            address: Yup.string().required('Address Name is required'),
            stateCode: Yup.string().required('State Name is required'),
            industryType: Yup.string().required('Industry is required'),
        }),
        onSubmit: async (value) => {
            const [code, name] = value.stateCode.split(',');
            const [countryCode, CounrtyName] = value.countrycode.split(',');
            try {
                setLoading(true);
                let data = {
                    organizationName: value.username,
                    email: value.email,
                    mobileNumber: value.mobileNumber,
                    phoneNumber: value.mobileNumber,
                    pincode: value.postalcode,
                    city: value.city,
                    state: name,
                    stateCode: code,
                    countryCode: countryCode,
                    country: CounrtyName,
                    industryType: value.industryType,
                    address: value.address,
                    website: value.website,
                };
                const response = await api.create(`${POST_REGISTER}`, data);
                if (response.success === true) {
                    Toast(response?.message, "success");
                    setTimeout(() => {
                        navigate('/auth-success-msg', { state: { id: 1, email: value?.email } });
                    }, 2000);
                }
            } catch (error) {
                toast.error(error.response?.data?.message, { autoClose: 2000 });
            } finally {
                setLoading(false);
            }
        }
    });

    useEffect(() => {
        fetchUserData()
    }, [])

    const handleStateClick = (value) => {
        const [code, name] = value.split(',');
        getCities(name)
    }

    const handleCityClick = (value) => {
        getPostalCode(value)
    }

    const fetchUserData = async () => {
        try {
            const res = await api.get(`${POST_GET_INDUSTRY_TYPE}`)
            if (res.success === true) {
                setIndustryTypeData(res?.data)
            }
        }
        catch (error) {
            console.log("error", error)
        }

    }

    // const getStates = async () => {
    //     try {
    //         const response = await api.get(`${GET_STATES}`)
    //         if (response.success === true) {
    //             setGetState(response.data)
    //         }
    //     } catch (error) {
    //         console.log("error:-", error);
    //     }
    // }

    const getCities = async (state) => {
        try {
            const data = { statName: state };
            const response = await api.create(`${POST_CITIES}`, data)
            if (response.success === true) {
                setGetCity(response.data)
            }
        } catch (error) {
            console.log(error, "error");
        }
    }

    const getPostalCode = async (city) => {
        try {
            const data = { cityName: city }
            const response = await api.create(`${POST_PINCODE}`, data)
            if (response.success === true) {
                setPostalCode(response.data)
            }

        } catch (error) {
            console.log(error, "error");
        }
    }
    return (
        <React.Fragment>
            <ToastContainer />
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <div className='sn-login-header-logo'>
                                <div className='sn-admin-login-logo'>
                                    <img src={logoheader} alt='Logo' />
                                </div>
                            </div>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-dark">Create New Account</h5>
                                        </div>

                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={handleSubmit}
                                                className="needs-validation">
                                                <div className="mb-3">
                                                    <Row>
                                                        <Col sm={6}>
                                                            <Label htmlFor="username" className="form-label">Name<span className="text-danger">*</span></Label>
                                                            <Input
                                                                data-tooltip-id="my-tooltip-1"
                                                                name="username"
                                                                autoComplete="new-password"
                                                                type="text"
                                                                placeholder="Enter name"
                                                                {...getFieldProps('username', { autoComplete: 'off' })}
                                                                className="form-control"
                                                                invalid={touched.username && errors.username}
                                                            />
                                                            {touched.username && errors.username && (
                                                                <FormFeedback type="invalid"></FormFeedback>
                                                            )}
                                                            <ReactTooltip
                                                                id="my-tooltip-1"
                                                                place="top"
                                                                className="custom-tooltip"
                                                            >
                                                                {touched.username && errors.username ? errors.username : ""}
                                                            </ReactTooltip>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <Label htmlFor="useremail" className="form-label">Email Address<span className="text-danger">*</span></Label>
                                                            <Input
                                                                name="email"
                                                                autoComplete="new-password"
                                                                data-tooltip-id="my-tooltip-2"
                                                                type="email"
                                                                placeholder="Enter email address"
                                                                {...getFieldProps('email', { autoComplete: 'off' })}
                                                                className="form-control"
                                                                invalid={touched.email && errors.email}
                                                            />
                                                            {touched.email && errors.email && (
                                                                <FormFeedback type="invalid"></FormFeedback>
                                                            )}
                                                            <ReactTooltip
                                                                id="my-tooltip-2"
                                                                place="top"
                                                                className="custom-tooltip-email"
                                                            >
                                                                {touched.email && errors.email ? errors.email : ""}
                                                            </ReactTooltip>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="mb-3">
                                                    <Row>
                                                        <Col sm={6}>
                                                            <Label htmlFor="contactNumber" className="form-label">Contact Number<span className="text-danger">*</span></Label>
                                                            <Input
                                                                name="mobileNumber"
                                                                autoComplete="new-password"
                                                                data-tooltip-id="my-tooltip-3"
                                                                type="tel"
                                                                placeholder="Enter Contact No."
                                                                {...getFieldProps('mobileNumber')}
                                                                className="form-control"
                                                                invalid={touched.mobileNumber && errors.mobileNumber}
                                                            />
                                                            {(touched.mobileNumber && errors.mobileNumber) && (
                                                                <FormFeedback type="invalid"></FormFeedback>
                                                            )}
                                                            <ReactTooltip
                                                                id="my-tooltip-3"
                                                                place="top"
                                                            >
                                                                {touched.mobileNumber && errors.mobileNumber ? errors.mobileNumber : ""}
                                                            </ReactTooltip>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <Label htmlFor="address" className="form-label">Address<span className="text-danger">*</span></Label>
                                                            <Input
                                                                data-tooltip-id="my-tooltip-7"
                                                                name="address"
                                                                autoComplete="new-password"
                                                                type="text"
                                                                placeholder="Enter Address"
                                                                {...getFieldProps('address')}
                                                                className="form-control"
                                                                invalid={touched.address && errors.address}
                                                            />
                                                            {(touched.address && errors.address) && <FormFeedback type="invalid"></FormFeedback>}
                                                            <ReactTooltip
                                                                id="my-tooltip-7"
                                                                place="top"
                                                            >
                                                                {touched.address && errors.address ? errors.address : ""}
                                                            </ReactTooltip>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="mb-3">
                                                    <div className="s_col_no_resize s_col_no_bgcolor">
                                                        <Row>
                                                            <Col sm={6}>
                                                                <Label htmlFor="country" className="form-label">Select Country<span className="text-danger">*</span></Label>
                                                                <select data-tooltip-id="my-tooltip-5"
                                                                    className={`form-select ${touched.countrycode && errors.countrycode ? 'is-invalid' : ''}`}
                                                                    name="countrycode" {...getFieldProps('countrycode')}
                                                                    value={getFieldProps('countrycode').value || `${industryTypeData?.countryList[0]?.code},${industryTypeData?.countryList[0]?.name}`}>
                                                                    <option value="" disabled>Select Country</option>
                                                                    <option value={`${industryTypeData?.countryList[0]?.code},${industryTypeData?.countryList[0]?.name}`}>{industryTypeData?.countryList[0]?.name}</option>
                                                                    {/* {industryTypeData?.countryList.map((ele, key) => {
                                                                        return (
                                                                            <>
                                                                                <option key={key} value={`${ele?.code},${ele?.name}`}>{ele?.name}</option>
                                                                            </>
                                                                        )
                                                                    })} */}
                                                                </select>
                                                                {(touched.countrycode && errors.countrycode) && <FormFeedback type="invalid"></FormFeedback>}
                                                                <ReactTooltip
                                                                    id="my-tooltip-5"
                                                                    place="top"
                                                                >
                                                                    {touched.countrycode && errors.countrycode ? errors.countrycode : ""}
                                                                </ReactTooltip>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <Label htmlFor="state" className="form-label">Select State<span className="text-danger">*</span></Label>
                                                                <select
                                                                    data-tooltip-id="my-tooltip-6"
                                                                    className={`form-select ${touched.stateCode && errors.stateCode ? 'is-invalid' : ''}`}
                                                                    name="stateCode"
                                                                    {...getFieldProps('stateCode')}
                                                                    onClick={(event) => handleStateClick(event.target.value)}>
                                                                    <option value="" disabled>Select State</option>
                                                                    {masterApiData?.stateCode?.map((ele, key) => {
                                                                        return (
                                                                            <>
                                                                                <option key={key} value={`${ele?.code},${ele?.name}`}>{ele?.name}</option>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </select>
                                                                {(touched.stateCode && errors.stateCode) && <FormFeedback type="invalid"></FormFeedback>}
                                                                <ReactTooltip
                                                                    id="my-tooltip-6"
                                                                    place="top"
                                                                >
                                                                    {touched.stateCode && errors.stateCode ? errors.stateCode : ""}
                                                                </ReactTooltip>
                                                            </Col>

                                                        </Row>
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <Row>
                                                        <Col sm={6}>
                                                            <Label htmlFor="City" className="form-label">City<span className="text-danger">*</span></Label>
                                                            <select data-tooltip-id="my-tooltip-8"
                                                                className={`form-select ${touched.city && errors.city ? 'is-invalid' : ''}`}
                                                                name="city"
                                                                type="text"
                                                                {...getFieldProps('city')}
                                                                onClick={(event) => handleCityClick(event.target.value)}
                                                            >
                                                                <option value="" disabled>Select City</option>
                                                                {getCity?.map((ele, key) => {
                                                                    return (
                                                                        <>
                                                                            <option key={key} value={`${ele}`}>{ele}</option>
                                                                        </>
                                                                    )
                                                                })}
                                                            </select>
                                                            {(touched.city && errors.city) && <FormFeedback type="invalid"></FormFeedback>}
                                                            <ReactTooltip
                                                                id="my-tooltip-8"
                                                                place="top"
                                                            >
                                                                {touched.city && errors.city ? errors.city : ""}
                                                            </ReactTooltip>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <Label htmlFor="postalncode" className="form-label">Postal-Code<span className="text-danger">*</span></Label>
                                                            <select
                                                                data-tooltip-id="my-tooltip-4"
                                                                className={`form-select ${touched.postalcode && errors.postalcode ? 'is-invalid' : ''}`}
                                                                name="postalcode"
                                                                {...getFieldProps('postalcode')}>
                                                                <option value="" disabled>Select Postal Code</option>
                                                                {postalCode?.map((ele, key) => {
                                                                    return (
                                                                        <>
                                                                            <option key={key} value={`${ele}`}>{ele}</option>
                                                                        </>
                                                                    )
                                                                })}
                                                            </select>

                                                            {(touched.postalcode && errors.postalcode) && <FormFeedback type="invalid"></FormFeedback>}
                                                            <ReactTooltip
                                                                id="my-tooltip-4"
                                                                place="top"
                                                            >
                                                                {touched.postalcode && errors.postalcode ? errors.postalcode : ""}
                                                            </ReactTooltip>
                                                        </Col>
                                                    </Row>
                                                </div>


                                                <div className="mb-3">
                                                    <Row>
                                                        <Col sm={6}>
                                                            <Label htmlFor="stateIndustry" className="form-label">Select Industry<span className="text-danger">*</span></Label>
                                                            <select data-tooltip-id="my-tooltip-9" name="stateIndustry" id="industryType" {...getFieldProps('industryType')} className={`form-select ${touched.industryType && errors.industryType ? 'is-invalid' : ''}`} >
                                                                <option value="" disabled>Select Industry</option>
                                                                {(industryTypeData?.industryType || [])?.map((ele, index) => {
                                                                    return (
                                                                        <>
                                                                            <option key={ele} value={ele}>{ele}</option>
                                                                        </>
                                                                    )
                                                                })}
                                                            </select>
                                                            {(touched.industryType && errors.industryType) && <FormFeedback type="invalid"></FormFeedback>}
                                                            <ReactTooltip
                                                                id="my-tooltip-9"
                                                                place="top"
                                                            >
                                                                {touched.industryType && errors.industryType ? errors.industryType : ""}
                                                            </ReactTooltip>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <Label htmlFor="websiteurl" className="form-label">Website Url<span className="text-danger d-none">*</span></Label>
                                                            <Input
                                                                name="website"
                                                                type="text"
                                                                placeholder="Enter Website Url."
                                                                {...getFieldProps('website')}
                                                                className="form-control"
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="mb-3">
                                                    <div className="col-sm">
                                                        <Input id='leadTermCheck'
                                                            type="checkbox"
                                                            {...getFieldProps('terms')}
                                                        />&nbsp;
                                                        <label htmlFor="Business" className="custom-control-label dt-cursor">
                                                            <Label for='leadTermCheck'>Yes, I understand and agree to the <Link to="https://salesninjacrm.com/terms-conditions" className="text-danger" target="_blank">Terms & Conditions
                                                            </Link> and <Link to="https://salesninjacrm.com/privacy-policy" className="text-danger" target="_blank">Privacy Policy
                                                                </Link></Label>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    {(touched.terms && errors.terms) &&
                                                        <>
                                                            <UncontrolledAlert color="danger" className="mb-xl-0" toggle={undefined}>
                                                                <strong>{errors.terms}</strong>
                                                            </UncontrolledAlert>
                                                        </>
                                                    }
                                                </div>
                                                <div className="mt-3">
                                                    <Button color="primary" className="btn btn-danger w-100" type="submit">
                                                        {loading === true ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                        Sign Up
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">Already have an account ? <Link to="/login" className="fw-semibold text-danger text-decoration-underline"> Signin </Link> </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
            <div className='sn-footer-botom-page'>
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default Register;




