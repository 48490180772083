import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import CommanDataTable from "../../../Components/Common/DataTable";
import { APIClient } from "../../../helpers/api_helper";
import { ENPLOYEE_GET_EMPLOYEE_LIST, GET_EMPLOYEES_LIST } from "../../../helpers/url_helper";
import { ToastContainer } from "react-toastify";
import { HandleErrorMessage } from "../../../Components/Common/HandleErrorMessage";
import moment from "moment";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { DashboardDataTable } from "../../../Components/Common/DashboardDataTbale";

const api = new APIClient();

const AppVersion = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const loginType = JSON.parse(localStorage.getItem("logintype"))
  const [isLoading, setIsLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [employeeData, setEmployeeData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");


  //For URL encoding
  const decodeUrlEncoded = (encodedString) => {
    if (!encodedString) return "-"; // Handle empty cases
    try {
      return decodeURIComponent(encodedString);
    } catch (e) {
      console.error("Error decoding URL:", e);
      return encodedString; // Return original if decoding fails
    }
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">S.No</span>,
      selector: (row, index) => index + 1, // Serial number based on index
      cell: (row, index) => <span>{index + 1}</span>, // Display serial number
      sortable: false,
      width: "5%",
    },
    {
      name: <span className="font-weight-bold fs-13">Employee Name</span>,
      selector: (row) => row.fullName,
      cell: (row) => (
        <div
          className="d-flex align-items-center w-100"
          style={{ textTransform: "capitalize" }}
        >
          <span style={{ flexGrow: 1 }}>{row.fullName}</span>
          <span
            style={{
              color: row.todayActiveStatus === 1 ? "green" : "red",
              marginLeft: "10px",
            }}
          >
            <div>
              <span
                className={`d-inline-block rounded-circle ${row.todayActiveStatus === 1 ? "bg-success" : "bg-danger"
                  }`}
                style={{ width: "10px", height: "10px" }}
              ></span>
            </div>
          </span>
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Device Name</span>,
      selector: (row) => row.deviceName,
      cell: (row) => <span>{row.deviceName}</span>,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">App Version</span>,
      selector: (row) => row.appVersion,
      cell: (row) => <span>{row.appVersion}</span>,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Last Call Sync Time</span>,
      selector: (row) =>
        moment(row.lastCallSync).format("DD MMM YYYY hh:mm:ss A"),
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-13">Last Recording Sync Time</span>
      ),
      selector: (row) =>
        moment(row.lastCallRecordingSync).format("DD MMM YYYY hh:mm:ss A"),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Last App Open Time</span>,
      selector: (row) =>
        row.appVersionUpdate
          ? moment(row.appVersionUpdate).format("DD MMM YYYY hh:mm:ss A")
          : "-", // Show "-" if appVersionUpdate is not present
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Activity Status</span>,
      selector: (row) => row.todayActiveStatus,
      cell: (row) => (
        <span>{row.todayActiveStatus === 1 ? "Active" : "Inactive"}</span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Recording Path</span>,
      selector: (row) => row.recordingStoragePath,
      cell: (row) => (
        <span>
          {row.recordingStoragePath && row.recordingStoragePath.length > 20 ? (
            <>
              {row.showFullPath
                ? decodeUrlEncoded(row.recordingStoragePath)
                : `${decodeUrlEncoded(row.recordingStoragePath).slice(
                  0,
                  20
                )}... `}
              <Button
                color="link"
                onClick={() => {
                  setEmployeeData((prevData) =>
                    prevData.map((emp) =>
                      emp._id === row._id
                        ? { ...emp, showFullPath: !emp.showFullPath }
                        : emp
                    )
                  );
                }}
              >
                {row.showFullPath ? "See Less" : "See More"}
              </Button>
            </>
          ) : (
            decodeUrlEncoded(row.recordingStoragePath) || "-"
          )}
        </span>
      ),

      sortable: true,
    },
  ];

  const fetchData = async (query) => {
    setIsLoading(true);
    try {
      let response;
      if (loginType === "employee") {
        response = await api.get(`${ENPLOYEE_GET_EMPLOYEE_LIST}?pg=${currentPage}&lm=${perPage}&query=${query}`);
      }
      if (loginType === "organization") {
        response = await api.get(`${GET_EMPLOYEES_LIST}?pg=${currentPage}&lm=${perPage}&query=${query}`);
      }

      if (response.success && response.success === true) {
        const updatedData = response.data.data.map((emp) => ({
          ...emp,
          showFullPath: false,
        }));
        setEmployeeData(updatedData);
        setTotalRows(response.data.totalCount);
      }
    } catch (error) {
      HandleErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  useEffect(() => {
    if (debouncedQuery.length >= 3 || debouncedQuery.length === 0) {
      fetchData(debouncedQuery);
    }
  }, [debouncedQuery, currentPage, perPage]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };



  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content sn-employees-managment">
        <Container fluid>
          <BreadCrumb title="APP VERSION" pageTitle="Employee App Versions" />
          <div className="custom-datatable">
            <Row className="g-3 mt-0">
              <Col lg={12}>
                <Card className="h-100">
                  <CardBody className="pt-0">
                    <CommanDataTable
                      column={columns}
                      tableData={employeeData}
                      handlePerRowsChange={handlePerRowsChange}
                      totalRows={totalRows}
                      currentPage={currentPage}
                      isLoading={isLoading}
                      handlePageChange={handlePageChange}
                      handleSearch={handleSearch}
                      setCurrentPage={setCurrentPage}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AppVersion;
