import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Col, Input, Row } from "reactstrap";
import Loader from "./Loader";
import { useLocation, useNavigate } from "react-router-dom";

const tableCustomStyles = {
    headCells: {
        style: {
            width: "130px",
            justifyContent: "center",
            // backgroundColor: '#dbdde0',
            fontWeight: "700",
            fontSize: "small",
            fontFamily: "Arial, Helvetica, sans-serif",
        },
    },
    cells: {
        style: {
            fontWeight: "500",
            width: "150px",
            fontSize: "small",
            justifyContent: "center",
            fontFamily: "Arial, Helvetica, sans-serif",
        },
    },

    headRow: {
        style: {
            // borderTop: '1px solid #ddd',
            background: "#fff2f2",
            fontWeight: "600",
            // borderRight: '1px solid #ddd'
        },
    },
    headCells: {
        style: {
            borderRight: "1px solid #fff2f2",
            borderLeft: "1px solid #ddd",
            borderBottom: "1px solid #fff2f2",
        },
    },

    cells: {
        style: {
            borderRight: "1px solid #fff",
            borderLeft: "1px solid #ddd",
            "&:first-child": {
                borderLeft: "1px solid #ddd",
            },
            "&:last-child": {
                borderRight: "1px solid #ddd",
            },
        },
    },
};

// const CommanDataTable = ({
//     handleSearch,
//     isLoading,
//     handleSort,
//     loading,
//     searchText,
//     column,
//     fixedHeaderScrollHeights,
//     tableData,
//     totalRows,
//     currentPage,
//     handlePageChange,
//     handlePerRowsChange,
//     inputClass,
// }) => {



//     const navigate = useNavigate();
//     const location = useLocation();

//     let paginationPerPage;


//     if (totalRows<=10) {
//         paginationPerPage = 10;
//     }else if(totalRows>=10 && totalRows < 25){
//         paginationPerPage = 25
//     } else if(totalRows>=25 && totalRows < 50){
//         paginationPerPage = 50;
//     }
//     const paginationRowsPerPageOptions = [10, 25, 50, 100];

//     // Track a unique key for DataTable to force re-render
//     const [tableKey, setTableKey] = useState(0);

//     useEffect(() => {
//         // Reset table key when data or currentPage changes
//         setTableKey(prevKey => prevKey + 1);
//     }, [tableData, currentPage]);

//     return (
//         <>
//             <div className="custom-datatable">
//                 <Row className="g-3 mt-0">
//                     <div className="sn-app-version-page-bk">
//                         {location.pathname === '/app-version' && (
//                             <button
//                                 className="btn btn-sm border border-rounded shadow backbtn-main"
//                                 onClick={() => navigate(-1)}
//                             >
//                                 <i className="ri-arrow-left-line m-1"></i> Back
//                             </button>
//                         )}
//                         <Col xxl={2} md={4} className="ms-auto">
//                             <Input
//                                 value={searchText}
//                                 type="text"
//                                 className={`form-control ${inputClass}`}
//                                 id="exampleInputrounded"
//                                 placeholder="Search by keyword..."
//                                 onChange={handleSearch}
//                             />
//                         </Col>
//                     </div>
//                     <Col
//                         lg={12}
//                         className="sn-tble-call-archive-mainn sn-team-number--employyes-btn sn-tble-bottom-scroll-main sn-total-calltype-btn-status sn-employee-managment-tble-detail"
//                         style={{ textAlign: "center", overflow: "scroll" }}
//                     >
//                         <DataTable
//                             key={tableKey} // Force re-render when data changes
//                             className="sn-managment-team-scroll-tble sn-tble-droup-down-edit-delete table-scroll"
//                             customStyles={tableCustomStyles}
//                             defaultSortAsc={true}
//                             columns={column}
//                             data={tableData}
//                             fixedHeaderScrollHeight={fixedHeaderScrollHeights || ""}
//                             fixedHeader
//                             striped
//                             search={true}
//                             pagination={true}
//                             paginationServer={true}
//                             paginationTotalRows={totalRows}
//                             paginationDefaultPage={currentPage}
//                             paginationPerPage={paginationPerPage}
//                             paginationRowsPerPageOptions={paginationRowsPerPageOptions}
//                             onChangePage={handlePageChange}
//                             onChangeRowsPerPage={handlePerRowsChange}
//                             noDataComponent={<div className="center-content">There are no records to display</div>}
//                             responsive
//                             progressPending={isLoading}
//                             progressComponent={<Loader />}
//                         />
//                     </Col>
//                 </Row>
//             </div>
//         </>
//     );
// };

const CommanDataTable = ({
    perPage,
    handleSearch,
    isLoading,
    handleSort,
    loading,
    searchText,
    column,
    fixedHeaderScrollHeights,
    tableData,
    totalRows,
    currentPage,
    handlePageChange,
    handlePerRowsChange,
    inputClass,
    setCurrentPage
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    // Track a unique key for DataTable to force re-render
    const [tableKey, setTableKey] = useState(0);

    // Set paginationPerPage dynamically based on totalRows
    const [paginationPerPage, setPaginationPerPage] = useState(10); // Default to 10

    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'ALL',
      };
    // Update paginationPerPage based on the totalRows condition
    useEffect(() => {
        let calculatedPerPage;
        if (totalRows <= 10) {
            calculatedPerPage = 10;
        } else if (totalRows > 10 && totalRows < 25) {
            calculatedPerPage = 10;
        } else if (totalRows > 25 && totalRows < 50) {
            calculatedPerPage = 10;
        } else if (totalRows > 50 && totalRows < 100) {
            calculatedPerPage = 10;
        } else if (totalRows > 10 && totalRows < 1000) {
            calculatedPerPage = 10;
        } else {
            calculatedPerPage = 10;
        }

        // Set the calculated value
        setPaginationPerPage(calculatedPerPage);
    }, [totalRows]); // Trigger on totalRows change

    // Persist the pagination values in localStorage (if needed)
    useEffect(() => {
        const savedPage = parseInt(localStorage.getItem('currentPage')) || 1;
        const savedPageSize = parseInt(localStorage.getItem('paginationPerPage')) || paginationPerPage;

        // Persist currentPage and paginationPerPage
        localStorage.setItem('currentPage', savedPage);
        localStorage.setItem('paginationPerPage', savedPageSize);
    }, [paginationPerPage]);

    // Reset table key when data or currentPage changes
    useEffect(() => {
        setTableKey(prevKey => prevKey + 1);
    }, [tableData, currentPage]);

    // Handler for page change
    const handlePageChangeInternal = (page) => {
        setCurrentPage(page); // Update currentPage state
        handlePageChange(page); // Call parent handler
    };

    // Handler for changing rows per page
    const handlePerRowsChangeInternal = (newPerPage, page) => {
        setPaginationPerPage(newPerPage); // Update paginationPerPage state
        handlePerRowsChange(newPerPage, page); // Call parent handler
    };

    return (
        <>
            <div className="custom-datatable">
                <Row className="g-3 mt-0">
                    <div className="sn-app-version-page-bk">
                        {location.pathname === '/app-version' && (
                            <button
                                className="btn btn-sm border border-rounded shadow backbtn-main"
                                onClick={() => navigate(-1)}
                            >
                                <i className="ri-arrow-left-line m-1"></i> Back
                            </button>
                        )}
                        <Col xxl={2} md={4} className="ms-auto">
                            <Input
                                value={searchText}
                                type="text"
                                className={`form-control ${inputClass}`}
                                id="exampleInputrounded"
                                placeholder="Search by keyword..."
                                onChange={handleSearch}
                            />
                        </Col>
                    </div>
                    <Col
                        lg={12}
                        className="sn-tble-call-archive-mainn sn-team-number--employyes-btn sn-tble-bottom-scroll-main sn-total-calltype-btn-status sn-employee-managment-tble-detail"
                        style={{ textAlign: "center", overflow: "scroll" }}
                    >
                        <DataTable
                            key={tableKey} // Force re-render when data changes
                            className="sn-managment-team-scroll-tble sn-tble-droup-down-edit-delete table-scroll"
                            customStyles={tableCustomStyles}
                            defaultSortAsc={true}
                            columns={column}
                            data={tableData}
                            fixedHeaderScrollHeight={fixedHeaderScrollHeights || ""}
                            fixedHeader
                            striped
                            search={true}
                            pagination={true}
                            paginationServer={true}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            paginationRowsPerPageOptions={[10,25,50,100]}
                            paginationPerPage={perPage}
                           paginationComponentOptions={paginationComponentOptions}
                            onChangePage={handlePageChangeInternal}
                            onChangeRowsPerPage={handlePerRowsChangeInternal}
                            noDataComponent={<div className="center-content">There are no records to display</div>}
                            responsive
                            progressPending={isLoading}
                            progressComponent={<Loader />}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default CommanDataTable;
