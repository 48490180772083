import React, { useEffect, useState } from 'react';
//constants
import { layoutModeTypes } from "../../Components/constants/layout";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import whatsappnew from "../../../src/assets/images/whatsappnew.png"
import mail from "../../../src/assets/images/mail.png"
import { APIClient } from '../../helpers/api_helper';
import { GET_SHARE_APP_LINK } from "../../helpers/url_helper";

const api = new APIClient();
// Mock data or get these from API or props
const users = { organizationId: 'RED-3064-6971' }; // Replace with actual logic
const appLink = 'https://play.google.com/store/apps/details?id=com.salesninjacrm&hl=en_IN'; // Replace with actual link




const LightDark = ({ layoutMode, onChangeLayoutMode, users }) => {
    const [appLink, setAppLink] = useState([])
    const mode = layoutMode === layoutModeTypes['DARKMODE'] ? layoutModeTypes['LIGHTMODE'] : layoutModeTypes['DARKMODE'];
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [text, setText] = useState(appLink);
    const [isCopied, setIsCopied] = useState(false);


    const closeBtn = (
        <button className="close" onClick={toggle} type="button">
            &times;
        </button>
    );
  


    const handleCopy = () => {
        navigator.clipboard.writeText(appLink).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        });
    };


    useEffect(() => {
        const getData = async () => {
            try {
                let response = await api.get(`${GET_SHARE_APP_LINK}`);
                if (response && response.success === true) {
                    const apiResponse = response.data;
                    setAppLink(apiResponse?.url)
                }
            } catch (error) {
                console.log(error);

            }
        };
        getData()
    }, []);


    const message = `
    Get your team connected with the Sales Ninja mobile app to start tracking calls and boosting productivity. You can share the app with your employees:
  
    *1. Download the Sales Ninja App*
  
    *2. Use Your Organization Code:* Share the unique organization code ${users?.organizationId} with your team to register under your organization.
  
    *3. Quick and Easy Setup:* Employees can simply enter the code during registration for instant access.
  
    ${appLink}`;
    
    const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
  
    const emailMessage  = `
    Get your team connected with the Sales Ninja mobile app to start tracking calls and boosting productivity. You can share the app with your employees:
    
    1. Download the Sales Ninja App
    
    2. Use Your Organization Code: Share the unique organization code "RED-3064-6971" with your team to register under your organization.
    
    3. Quick and Easy Setup: Employees can simply enter the code during registration for instant access.
    
    Download the app here: http://137.59.52.100:4000/public/get-link/RED-3064-6971
    `;
    
    // Encode the message for URL
    // const encodedMessage = encodeURIComponent(emailMessage);
    

    // Encode the message for URL
    const encodedemailMessage = encodeURIComponent(emailMessage);

    return (
        <div className="ms-1 header-item d-sm-flex  salen-ninja-modal-body-social">
            <div className='sn-sales-ninja-share-btn' onClick={toggle}>
                <a href='javascript:;' ><i className="ri-share-line"></i> Share</a>
            </div>

            <div className='sales-ninja-modal-share'>
                <Modal className='sales-body-modal-main' isOpen={modal} toggle={toggle}>
                    <ModalHeader className='sales-ninja-close-modal-btn' toggle={toggle} close={closeBtn}>
                        Invite Your Team to Sales Ninja !
                    </ModalHeader>
                    <ModalBody className='sales-ninja-modal-body-main'>
                        <p>Get your team connected with the Sales Ninja mobile app to start tracking calls and boosting productivity.
                            you can share the app with your employees:</p>

                        <div className='sales-ninja-modal-share-txt'>
                            <p><strong>1. Download the Sales Ninja App</strong></p>
                            <p><strong>2. Use Your Organization Code</strong>: Share the unique organization code {users?.organizationId} with your team to register under your organization.</p>
                            <p><strong>3. Quick and Easy Setup</strong>: Employees can simply enter the code during registration for instant access.</p>
                        </div>

                        <div className='sales-ninja-copyt-url-btn'>
                            <input
                                type="text"
                                value={appLink}
                                placeholder="Enter text to copy"
                                style={{ padding: '10px', }}
                            />
                            <button onClick={handleCopy} style={{ padding: '10px' }}>
                                {isCopied ? <i className="ri-file-copy-line"></i> : <i className="ri-file-copy-line"></i>}
                            </button>
                        </div>

                        <div className='salen-ninak-social-media-list'>
                            <h6>Share with your community</h6>
                            <ul>
                                <li><a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                                    <img src={whatsappnew} alt='' />
                                </a></li>
                                <li>
                                    <a 
                                        href={`https://mail.google.com/mail/?view=cm&fs=1&to=&su=${encodeURIComponent('Sales Ninja App Setup')}&body=${encodedemailMessage}`} 
                                        target='_blank'>
                                        <img src={mail} alt="Share via email" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </ModalBody>

                </Modal>
            </div>
            <a href='https://salesninjacrm.freshdesk.com/support/home' target='_blank' title='Support' className='salen-ninja-help-support-btn'><i className=' ri-customer-service-2-line'></i></a>
        </div>
    );
};

export default LightDark;
