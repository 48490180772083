import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "reactstrap";
import {
  EMPLOYEE_PROFILE,
  TOP_CALLER_API,
  TOP_PERFORMER_API,
} from "../../helpers/url_helper";
import { APIClient } from "../../helpers/api_helper";
 
import {
  CurrentDate,
  PreviousDate,
} from "../../Components/Common/DefaultDateSet";
import TopPerformance from "../../Components/Common/TopPerformance";
const api = new APIClient();
 
//callerData
const TopPerformanceEmployee = ({
  formattedDates,
  selectedEmployeeOptions,
  employeeData,
  teamIndu,
}) => {
  const [tooltiptopCaller, setTooltipAgent] = useState(false);
  const toggleAgent = () => setTooltipAgent(!tooltiptopCaller);
 
  const [tooltiptopDialer, setTooltipDialer] = useState(false);
  const toggleADialertop = () => setTooltipDialer(!tooltiptopDialer);
 
  const [tooltiptopHighest, setTooltipHighest] = useState(false);
  const toggleAHighest = () => setTooltipHighest(!tooltiptopHighest);
 
  const [tooltiptopAnswered, setTooltipAnswered] = useState(false);
  const toggleAAnswered = () => setTooltipAnswered(!tooltiptopAnswered);
 
  const loginType = JSON.parse(localStorage.getItem("logintype"));
 
  //sortedCallers
  const [topDialer, setTopDialer] = useState([]);
  const [totalConnect, setTotalConnect] = useState({ totalDuration: 0 });
  const [totalAnswer, setTotalAnswer] = useState([]);
  const [topPerformer, setTopPerformer] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [teamState, setTeamState] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [callData, setCallData] = useState([]);
  const previewsDate = PreviousDate();
  const currentDate = CurrentDate();
  const [topEmployeeData, setTopEmployeeData] = useState([]);
 
  const [selectedFromDate, setSelectedFromDate] = useState(
    previewsDate + "T00:00:00"
  );
 
  const [selectedToDate, setSelectedToDate] = useState(
    currentDate + "T23:59:59"
  );
  let filteredData;
  const formattedStartDate = new Date(selectedFromDate);
  const formattedEndDate = new Date(selectedToDate);
 
  // Convert formatted startDate and endDate to timestamps
  const startDate = new Date(formattedStartDate).getTime();
  const endDate = new Date(formattedEndDate).getTime();
  const permissionType = JSON.parse(localStorage.getItem("permission"));
 
  const convertToTimestamp = (dateStr) => {
    return new Date(dateStr).getTime();
  };
  const navigate = useNavigate();
 
  //For navigation
 
  const handleNavigationToTopCallers = () => {
    navigate("/topCallers-report");
  };
  const handleNavigationToTopDialers = () => {
    navigate("/topDialers-report");
  };
  const handleNavigationTopDuration = () => {
    navigate("/topCallDuration-report");
  };
  const handleNavigationTotalConnected = () => {
    navigate("/topConnected-report");
  };
  // Default to current timestamp for the first API call
  const fetchCallerData = async (defaultId, updatedIds, strDate, enndDate) => {
    const today = new Date();
 
    // End of the day (23:59:00)
    const endTimeStamp = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      0
    ).getTime();
 
    let data = {};
    if (defaultId.length > 0) {
      data = {
        startRange: strDate,
        endRange: endTimeStamp,
        employeeIds: defaultId,
      };
    } else {
      data = {
        startRange: strDate,
        endRange: enndDate,
        employeeIds: updatedIds,
      };
    }
    try {
      const response = await api.create(TOP_PERFORMER_API, data);
      if (response.data) {
        const { topCaller, topDialer, highestCallDuration, topAnswered } = response.data;
 
          setTopPerformer({
          TopCallerFullName: topCaller?.fullName,
          TopCallerCallCount: topCaller?.count,
          TopCallerProfileImageUrl: topCaller?.profileImageUrl,
 
          TopDialerFullName: topDialer?.fullName,
          TopDialerCallCount: topDialer?.count,
          TopDialerProfileImageUrl: topDialer?.profileImageUrl,
 
          HighestDurationFullName: highestCallDuration?.fullName,
          HighestDurationDuration: highestCallDuration?.count,
          HighestDurationProfileImageUrl: highestCallDuration?.profileImageUrl,
 
          TopAnsweredFullName: topAnswered?.fullName,
          TopAnsweredAnswerCount: topAnswered?.count,
          TopAnsweredProfileImageUrl: topAnswered?.profileImageUrl,
        });
      } else {
        console.error("Error: API response is not successful.");
      }
    } catch (error) {
      console.error("Error fetching caller data:", error);
    }
  };
 
  const fetchData = async () => {
    const updatedIds = selectedEmployeeOptions.map((userid) => userid?.id);
    try {
      const defaultId =
        selectedEmployeeOptions?.map((option) => option?.id) || [];
      if (formattedDates?.startDate && formattedDates?.endDate) {
        const newStartTime = convertToTimestamp(formattedDates.startDate);
        const newEndTime = convertToTimestamp(formattedDates.endDate);
        if (
          loginType === "organization" ||
          (loginType === "employee" && permissionType.length > 0)
        )
          await fetchCallerData(
            defaultId,
            updatedIds,
            newStartTime,
            newEndTime
          );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
 
  useEffect(() => {
    if (employeeData.length > 0) {
      fetchData();
    }
  }, [employeeData]);
 
  useEffect(() => {
    setTeamState(teamIndu);
  }, [teamIndu]);
 
  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;
 
    return `${hours}h ${minutes}m ${seconds}s`;
  };
 
  const fetchNewData = async (page, size = perPage, defaultId) => {
    const response = await api.get(`${EMPLOYEE_PROFILE}`);
    const id = response?.data?._id;
    const newStartTime = convertToTimestamp(formattedDates.startDate);
    const newEndTime = convertToTimestamp(formattedDates.endDate);
    let data = {
      startRange: newStartTime,
      endRange: endDate,
      filterField: "callCount",
      employeeIds: id,
    };
 
    setIsLoading(true);
    try {
      const response = await api.create(`${TOP_CALLER_API}`, data);
      if (response.success) {
        filteredData = response.data.map((item) => ({
          fullName: item.fullName,
          overall_rank: item.overall_rank,
          team_ranks: item.team_ranks,
          callCount: item.callCount,
          totalOutgoing: item.totalOutgoing,
          totalIncoming: item.totalIncoming,
          totalAnswered: item.totalAnswered,
          team_ranks: item.team_ranks,
          profileImageUrl: item.profileImageUrl,
          id: item._id,
          totalDuration: item.totalDuration,
        }));
        setCallData(filteredData);
 
        filteredData.forEach((item) => {
          setTopEmployeeData(item);
        });
      } else {
        console.error("Error fetching data:", response.message);
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
 
  useEffect(() => {
    try {
      const defaultId =
        selectedEmployeeOptions?.map((option) => option?.id) || [];
      if (loginType === "employee" && permissionType.length == 0) {
        fetchNewData(currentPage, perPage, defaultId);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [employeeData]);
 
  return (
    <div>
      <div className="row">
        {loginType === "organization" || (loginType === "employee" && permissionType.length > 0) ? (
          <div className="col-md-12 col-sm-12">
            <div className="row-- sn-top-cannected-employee">
             
             <TopPerformance topEmployeeData={topEmployeeData}  employeeData={employeeData} topPerformer={topPerformer} formatDuration={formatDuration}/>
            </div>
          </div>
        ) : (
          <div className="col-md-12 col-sm-12">
            <div className="row sn-top-cannected-employee">
             
            <TopPerformance  employeeData={employeeData} topEmployeeData={topEmployeeData} formatDuration={formatDuration}/>
 
            </div>
          </div>
          // })
        )}
      </div>
    </div>
  );
};
 
export default TopPerformanceEmployee