import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Input,
} from "reactstrap";
import moment from "moment";
import { CALL_REPORT } from "../../helpers/url_helper";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  formatDate,
  formatDateTime,
} from "../../Components/Common/FormDateTime";
import EmployeeSelect from "../../Components/Common/EmployeeSelect";
import TeamSelect from "../../Components/Common/TeamSelect";
import EmployeeCallType from "../../Components/Common/EmployeeCallType";
import { DatePicker } from "../../Components/Common/Flatpicker";
import { APIClient } from "../../helpers/api_helper";
import {
  CurrentDate,
  PreviousDate,
} from "../../Components/Common/DefaultDateSet";
import { HandleErrorMessage } from "../../Components/Common/HandleErrorMessage";
import {
  formatToSystemTimezone,
  toCamelCase,
} from "../../Components/Common/ToCamelCase";
import SubExpired from "../../Components/Common/subExpired";
import { useLocation, useNavigate } from "react-router-dom";
import { useProfileData } from "../../Components/Hooks/UserHooks";
import CommanDataTable from "../../Components/Common/DataTable";
import { addMonths } from "../../Components/Common/FlatePicker";

const api = new APIClient();

const CallReport = () => {
  document.title = "Call Report";
  const navigate = useNavigate();
  const permissionType = JSON.parse(localStorage.getItem("permission"));
  const viewReportPermission = permissionType?.includes("VIEW_REPORT");
  const loginType = JSON.parse(localStorage.getItem("logintype"));
  const { profileData } = useProfileData();

  const previewsDate = PreviousDate();
  const currentDate = CurrentDate();

  const params = new URLSearchParams(window.location.search);
  const date = params.get('date'); // Retrieve the 'date' parameter
  console.log("Output: '2024-12-10' ye date jo", date); // Output: '2024-12-10'

  // filter data
  const [selectedFromDate, setSelectedFromDate] = useState(
    previewsDate + "T00:00:00"
  );
  const [selectedToDate, setSelectedToDate] = useState(
    currentDate + "T23:59:59"
  );
  const [selectedFromDateTwo, setSelectedFromDateTwo] = useState(
    previewsDate + "T00:00:00"
  );
  const [selectedToDateTwo, setSelectedToDateTwo] = useState(
    currentDate + "T23:59:59"
  );

  const [EmployeeData, setEmployeeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpiredModalOpen, setIsExpiredModalOpen] = useState(true);

  const [callHData, setCallHData] = useState([]);

  // callType state
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [prevEmployeesIds_Test, setPrevEmployeesIds_Test] = useState([]);

  // employee select
  const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState([]);
  const [selectedEmployeeOptions2, setSelectedEmployeeOptions2] = useState([]);

  // Pagination settings
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFirstEffectRun, setIsFirstEffectRun] = useState(false);
  const [error, setError] = useState("");

  //select team state Not working
  const [teamIndu, setTeamIndu] = useState([]);
  const [EmployeeDefaultWithoutTeam, setEmployeeDefaultWithoutTeam] = useState([]);
  //search field state
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const [remainingDays, setRemainingDays] = useState(null);

  // flatPicker DateTime
  const formattedStartTime = formatDateTime(selectedFromDate);

  let formattedEndTime = formatDateTime(selectedToDate);

  // flatPicker Date
  const formattedStartDate = formatDate(selectedFromDate);
  const formattedEndDate = formatDate(selectedToDate);

  // const formattedStartDateTwo = formatDate(selectedFromDate);
  // const formattedEndDateTwo = formatDate(selectedToDate);

  const handleFromDateChange = (dates) => {
    const newFromDate = dates[0];
    setSelectedFromDate(newFromDate);
    const newToDate = addMonths(newFromDate, 3);
    setSelectedToDate(newToDate);
  };

  const handleToDateChange = (dates) => {
    setSelectedToDate(dates[0]);
  };

  //table data
  const column = [
    {
      name: <span className="font-weight-bold fs-13"> SN.</span>,
      width: "5%",
      selector: (row, index) => index + 1,
    },

    {
      name: <span className="font-weight-bold fs-13">Employee</span>,
      selector: (row) => toCamelCase(row?.employeeName),
      cell: (row) => (
        <div className="mt-2 mb-2">
          <h5
            className="font-weight-bold fs-13"
            style={{ textTransform: "capitalize" }}
          >
            {row?.employeeName}
          </h5>
          <h6 className="text-center- font-weight-bold fs-13">
            {row?.employeeNumber}
          </h6>
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Client Name</span>,
      selector: (row) => toCamelCase(row?.name),
      cell: (row) => (
        <div className="mt-2 mb-2">
          <h5
            className="font-weight-bold fs-13"
            style={{ textTransform: "capitalize" }}
          >
            {row?.name}
          </h5>
          <h6 className="text-center- font-weight-bold fs-13">
            {row?.phoneNumber}
          </h6>
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Date & Time</span>,
      selector: (row) => new Date(`${row?.date} ${row?.time}`).getTime(), // Timestamp for sorting
      cell: (row) => (
        <div className="mt-2 mb-2">
          <h5
            className="font-weight-bold fs-13"
            style={{ textTransform: "capitalize" }}
          >
            {row?.date}
          </h5>
          <h6 className="text-center font-weight-bold fs-13">{row?.time}</h6>
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Call Type</span>,
      selector: (cell) => {
        switch (cell.type) {
          case "OUTGOING":
            return (
              <>
                <div style={{ textTransform: "capitalize" }}>
                  {" "}
                  <i className="mdi mdi-phone-outgoing sn-out-going-text" />{" "}
                  <span className="badge badge-soft-info sn-out-going-call">
                    {" "}
                    {cell.type}
                  </span>
                </div>
              </>
            );
          case "REJECTED":
            return (
              <>
                <i className="mdi mdi-phone-remove sn-out-rejected-text" />
                <span className="badge badge-soft-warning sn-out-rejected-call">
                  {" "}
                  {cell.type}{" "}
                </span>
              </>
            );
          case "MISSED":
            return (
              <>
                <i className="mdi mdi-phone-missed sn-out-missed-text" />
                <span className="badge badge-soft-danger sn-out-missed-call">
                  {" "}
                  {cell.type}{" "}
                </span>
              </>
            );
          case "INCOMING":
            return (
              <>
                <i className="mdi mdi-phone-incoming text-success" />
                <span className="badge badge-soft-success sn-out-incoming-call">
                  {" "}
                  {cell.type}{" "}
                </span>
              </>
            );
          default:
            return (
              <span className="badge badge-soft-success"> {cell.type} </span>
            );
        }
      },
    },
    // {
    //   name: <span className='font-weight-bold fs-13'>Status</span>,
    //   selector: (cell) => {
    //     switch (cell.status) {
    //       case "DELETED":
    //         return <span className="badge bg-danger"> {cell.status} </span>;
    //       case "NOT UPDATED":
    //         return <span className="badge bg-info"> {cell.status} </span>;
    //       case "FOUND":
    //         return <span className="badge bg-success"> {cell.status} </span>;
    //       default:
    //         return <span className="badge bg-success"> {cell.status} </span>;
    //     }
    //   },
    // }
    // },
    {
      name: <span className="font-weight-bold fs-13">Call Recording</span>,
      width: "37%",
      selector: (row) => (
        <>
          {row?.recording ? (
            <div
              style={{
                display: "inline-grid",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              <span style={{ height: "10px" }}>{row?.duration}</span>
              <audio
                style={{ height: "40px", marginTop: "15px" }}
                src={row?.recording}
                controls
              />
            </div>
          ) : row?.duration && row.duration !== "00:00:00" ? (
            <div
              style={{
                display: "inline-grid",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              {["OUTGOING", "INCOMING", "REJECTED", "MISSED"].includes(
                row.type
              ) ? (
                <>
                  <span style={{ height: "10px" }}>{row?.duration}</span>
                  <span style={{ marginTop: "10px", color: "warning" }}>
                    Recording sync in progress...
                  </span>
                </>
              ) : (
                "Call Not Connected"
              )}
            </div>
          ) : (
            "Call Not Connected"
          )}
        </>
      ),
    },
    {
      name: (
        <span className="font-weight-bold fs-13" style={{ textAlign: "left" }}>
          Notes
        </span>
      ),
      selector: (row) => row.notes,
      cell: (row) => (
        <div style={{ textAlign: "left", width: "100%" }}>{row.notes}</div>
      ),
    },

    // {
    //   name: <span className="font-weight-bold fs-13">Notes</span>,
    //   selector: (row) => row.notes,
    //   width:'200px',
    // },
  ];
  const location = useLocation();

  let selectedDate = location.state?.selectedDate;

  const convertToISODate = (dateString) => {
    const dateObject = new Date(dateString);

    if (!isNaN(dateObject)) {
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const day = String(dateObject.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    return null;
  };
  const [isoDates, setIsoDates] = useState(convertToISODate(selectedDate))

  useEffect(() => {
    // Clear selectedDate by navigating to the same route without state
    if (selectedDate) {
      navigate(location.pathname, { replace: true }); // Clears state while keeping the current URL
    }
  }, []);
  // Debounce search query
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
      setCurrentPage(1);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  // Fetch data when debounced query, currentPage, or perPage changes
  const fetchData = async () => {
    if (debouncedQuery.length >= 3 || debouncedQuery.length === 0) {
      try {
        let defaultId =
          prevEmployeesIds_Test.length > 0
            ? prevEmployeesIds_Test
            : selectedEmployeeOptions2?.map((option) => option?.id) || []; // EmployeeData
        if (selectedEmployeeOptions.length === 0) {
          defaultId = [];
        }
        await callHistoryData(
          currentPage,
          perPage,
          defaultId,
          "",
          debouncedQuery
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  // useEffect(() => {
  //   if (!isFirstEffectRun && EmployeeData?.length > 0) {
  //     const defaultId = EmployeeData?.map((userid) => userid?.id);
  //     callHistoryData(currentPage, perPage, defaultId);
  //     setPrevEmployeesIds_Test(defaultId);
  //     // setSelectedFromDateTwo(new Date());
  //     // setSelectedFromDateTwo(new Date());
  //     setSelectedEmployeeOptions2(EmployeeData);
  //     setIsFirstEffectRun(true); // Mark as executed

  //   }
  // }, [EmployeeData]);
  useEffect(() => {
    let updatedIds;
    if (!isFirstEffectRun && EmployeeData?.length > 0) {
      if (teamIndu.length === 0) {
        console.log("EmployeeDefaultWithoutTeam", EmployeeDefaultWithoutTeam);
        updatedIds = EmployeeDefaultWithoutTeam?.map((userid) => userid?.id);
        callHistoryData(currentPage, perPage, updatedIds);
        setPrevEmployeesIds_Test(updatedIds)
        setSelectedEmployeeOptions2(EmployeeData);
        setIsFirstEffectRun(true); // Mark as executed
      } else {
        console.log("EmployeeDefaultsssssssssssssssssss", EmployeeData);
        updatedIds = EmployeeData?.map((userid) => userid?.id);
        callHistoryData(currentPage, perPage, updatedIds);
        setPrevEmployeesIds_Test(updatedIds)
        setSelectedEmployeeOptions2(EmployeeData);
        setIsFirstEffectRun(true); // Mark as executed
      }
      // if (updatedIds.length > 0) {
      //   callHistoryData(currentPage, perPage, updatedIds);
      // }
      setIsFirstEffectRun(true);
    }
  }, [EmployeeData]);

  useEffect(() => {
    if (isFirstEffectRun === true) {
      fetchData();

    }
  }, [debouncedQuery, currentPage, perPage]);

  useEffect(() => {

    const calculateRemainingDays = () => {
      if (
        profileData?.subscriptionEndDate &&
        profileData?.subscriptionStartDate
      ) {
        const endDate = new Date(profileData?.subscriptionEndDate);
        const startDate = new Date(profileData?.subscriptionStartDate);
        const currentDate = new Date();

        const totalDays = Math.ceil(
          (endDate - startDate) / (1000 * 60 * 60 * 24)
        );
        const remainingDays = Math.ceil(
          (endDate - currentDate) / (1000 * 60 * 60 * 24)
        );
        setRemainingDays(remainingDays > 0 ? remainingDays : 0);
        const interval = setInterval(() => {
          const newCurrentDate = new Date();
          const newRemainingDays = Math.ceil(
            (endDate - newCurrentDate) / (1000 * 60 * 60 * 24)
          );
          setRemainingDays(newRemainingDays);
        }, 1000 * 60 * 60 * 24);

        return () => clearInterval(interval);
      } else {
        setRemainingDays(null);
      }
    };

    calculateRemainingDays();
  }, [profileData]);

  // Search field handler
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  console.log(formatDate(selectedFromDate));

  console.log(formatDate(selectedToDate));

  //table data
  const handleSubmit = () => {
    if (selectedEmployeeOptions.length === 0) {
      setError("You must select at least one");
    } else {
      const updatedIds = selectedEmployeeOptions?.map((userid) => userid?.id);
      setPrevEmployeesIds_Test(updatedIds);
      setSelectedFromDateTwo(formatDate(selectedFromDate));
      setSelectedToDateTwo(formatDate(selectedToDate));
      callHistoryData(currentPage, 10, "", updatedIds); //perpage
      setCurrentPage(1);
      setSelectedEmployeeOptions2(selectedEmployeeOptions);
      setPerPage(10);
      setError("");
    }
  };
  const callHistoryData = async (
    page,
    size = perPage,
    defaultId,
    updatedIds,
    searchTerm = ""
  ) => {
    setIsLoading(true);
    try {
      const callTy = selectedOptions?.map((option) => option?.value) || [];
      let data = {};
      if (defaultId.length > 0) {
        data = {
          startDate: isoDates ? isoDates : formatDate(selectedFromDateTwo),
          endDate: isoDates ? isoDates : formatDate(selectedToDateTwo),
          startTime: formattedStartTime,
          endTime: formattedEndTime,
          callType: callTy,
          employeeIds: defaultId,
        };
      } else {
        data = {
          startDate: isoDates ? isoDates : formatDate(selectedFromDate),
          endDate: isoDates ? isoDates : formatDate(selectedToDate),
          startTime: formattedStartTime,
          endTime: formattedEndTime,
          callType: callTy,
          employeeIds: updatedIds,
        };
      }
      const response = await api.create(
        `${CALL_REPORT}?pg=${page}&lm=${size}&query=${searchTerm}`,
        data
      );
      setIsLoading(false);

      if (response && response.success === true) {
        const apiResponse = response.data;
        let tableData = (apiResponse?.paginatedResults || [])?.map(
          (item, index) => {
            const recordingUserStatus = item.recordingStatus?.replace(
              /_/g,
              " "
            );
            const dateTimeString = item.dateTime;
            const systemDateTimeString = formatToSystemTimezone(dateTimeString);
            return {
              employeeName: Array.isArray(item?.employeeDescription)
                ? item.employeeDescription[0]?.fullName
                : "unknown",
              employeeNumber: Array.isArray(item?.employeeDescription)
                ? item.employeeDescription[0]?.mobileNumber
                : "-",
              name: item?.name || "unknown",
              phoneNumber: item?.phoneNumber || "-",
              date: dateTimeString
                ? moment(dateTimeString).format("DD MMM YYYY")
                : "-",
              time: systemDateTimeString || "-",
              duration: new Date(item.duration * 1000)
                .toISOString()
                .slice(11, 19),
              type: item?.type || "-",
              notes: item?.note || "-",
              status: recordingUserStatus || "-",
              recording: item?.callRecording,
            };
          }
        );
        setCallHData(tableData);
        setTotalRows(apiResponse.totalCount);
      }
    } catch (error) {
      if (!remainingDays === 0) {
        HandleErrorMessage(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  //reset button
  const resetInputHistory = () => {
    setSelectedToDate("");
    setSelectedFromDate("");
    setSelectedOptions([]);
    setSelectedEmployeeOptions([]);
    setTeamIndu([]);
  };

  const handleClose = () => {
    setIsExpiredModalOpen(false);
    navigate("/planPackage");
  };

  return (
    <React.Fragment>
      {remainingDays === 0 && isExpiredModalOpen && (
        <SubExpired
          onClose={handleClose}
          profileData={profileData?.selectedPlanDetails?.planName}
          remainingDays={remainingDays}
        />
      )}
      <div className="page-content sn-call-report-page-top">
        <Container fluid>
          <BreadCrumb title="Call Logs Report" pageTitle="Call Report" />
          <Card className="h-100">
            <CardBody>
              <Row className="sn-header-select-employess-home sn-team-managment-tble sn-top-inputs-heading">
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <DatePicker
                    labelContent="From Date"
                    getDate={isoDates ? isoDates : selectedFromDate}
                    options={{
                      disable: [
                        {
                          from: new Date(),
                        },
                      ],
                      enableTime: true,
                      dateFormat: "Y-m-d h:i K",
                      maxDate: new Date(
                        new Date().setDate(new Date().getDate())
                      ),
                    }}
                    onChange={handleFromDateChange}
                  />
                </div>

                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xs-12">
                  <DatePicker
                    labelContent="To Date"
                    getDate={isoDates ? isoDates : selectedToDate}
                    options={{
                      enable: selectedFromDate
                        ? [
                          {
                            from: isoDates ? isoDates : selectedFromDate,
                            to: addMonths(isoDates ? isoDates : selectedFromDate, 3),
                          },
                        ]
                        : [
                          {
                            from: new Date(),
                          },
                        ],
                      enableTime: true,
                      dateFormat: "Y-m-d h:i K",
                    }}
                    onChange={handleToDateChange}
                  />
                </div>

                {((viewReportPermission && loginType === "employee") ||
                  loginType === "organization") && (
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xs-12">
                      <TeamSelect
                        setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                        setSelectedOptions={setSelectedOptions}
                        teamIndu={teamIndu}
                        setTeamIndu={setTeamIndu}
                        labelContent="Select Team"
                      />
                    </div>
                  )}

                {((viewReportPermission && loginType === "employee") ||
                  loginType === "organization") && (
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xs-12">
                      <EmployeeSelect
                        EmployeeDefault={teamIndu.length === 0?EmployeeDefaultWithoutTeam:EmployeeData}
                        setEmployeeDefault={setEmployeeData}
                        teamIndu={teamIndu}
                        selectedEmployeeOptions={selectedEmployeeOptions}
                        setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                        setSelectedEmployeeOptions2={setSelectedEmployeeOptions2}
                        labelContent="Select Employee"
                        error={error}
                        setError={setError}
                        EmployeeDefaultWithoutTeam={EmployeeDefaultWithoutTeam}
                        setEmployeeDefaultWithoutTeam={setEmployeeDefaultWithoutTeam}
                      />
                    </div>
                  )}

                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 sn-header-select-employess-home">
                  <FormGroup>
                    <EmployeeCallType
                      labelContent="Select Call Type"
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                    />
                  </FormGroup>
                </div>

                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xs-12">
                  <FormGroup>
                    <Label for="exampleSelect"></Label>
                    <div
                      id="exampleSelect"
                      className="sn-call-apply-btn-reset mt-2"
                    >
                      <Button
                        id="exampleSelect"
                        type="submit"
                        color="danger"
                        className=""
                        onClick={handleSubmit}
                        disabled={isLoading}
                      >
                        Apply
                      </Button>
                      <Button
                        className=""
                        style={{ marginLeft: "10px" }}
                        id="exampleSelect"
                        outline
                        color="danger"
                        onClick={resetInputHistory}
                        disabled={isLoading}
                      >
                        Reset
                      </Button>
                    </div>
                  </FormGroup>
                </div>
              </Row>

              <Row className="sn-user-tble-manin-manage">
                <Col lg={12}>
                  <CommanDataTable
                    perPage={perPage}
                    setCurrentPage={setCurrentPage}
                    column={column}
                    tableData={callHData}
                    handleSearch={handleSearch}
                    searchText={query}
                    handlePerRowsChange={handlePerRowsChange}
                    totalRows={totalRows}
                    currentPage={currentPage}
                    isLoading={isLoading}
                    handlePageChange={handlePageChange}
                    noData="There are no records to display"
                    inputClass={
                      (callHData && callHData.length > 0) || query
                        ? "d-block"
                        : "d-none"
                    }
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CallReport;
