import React, { useState } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const SelectDatePicker = ({ selectedDates, setSelectedDates }) => {
    const [startDate, setStartDate] = useState(null);   
      
    const disabledDate = (current) => {
        if (!startDate) {
            return current.isAfter(dayjs(), 'day'); // Disable tomorrow and future dates
        }
        if (!startDate) return false; 

        const minDate = startDate.subtract();
        // const minDate = startDate.subtract(90, 'days');
        const maxDate = startDate.add(90, 'days');
 
        return current.isBefore(minDate, 'day') || current.isAfter(maxDate, 'day');
    };
  
    // Handle date range selection
    const onRangeChange = (dates) => {
        if (dates && dates.length === 2) {
            const start = dayjs(dates[0]);
            const end = dayjs(dates[1]);
            setSelectedDates([start, end]); 
            setStartDate(start); 
        } else {
            setSelectedDates([]);
            setStartDate(null);
        }
    };

    const rangePresets = [
        {
            label: 'Today',
            value: [dayjs(), dayjs()],
        },
        {
            label: 'Yesterday',
            value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
        },
        {
            label: 'Last 7 Days',
            value: [dayjs().subtract(7, 'days'), dayjs()],
        },
        {
            label: 'Last 30 Days',
            value: [dayjs().subtract(30, 'days'), dayjs()],
        },
        {
            label: 'Last 90 Days',
            value: [dayjs().subtract(90, 'days'), dayjs()],
        },
    ];

    return (
        <RangePicker
            presets={rangePresets}
            value={selectedDates}
            format="YYYY-MM-DD"
            onCalendarChange={onRangeChange} 
            disabledDate={disabledDate} // Disable dates outside the 90-day window
            style={{ width: '100%' }}
            dropdownClassName="createDateRangePicker"
            // showTime={{
            //     format: 'hh:mm A',
            //     use12Hours: true,
            // }}
        />
    );
};

export default SelectDatePicker;
