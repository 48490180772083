import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { TOP_CALLER_API } from "../../helpers/url_helper";
import { APIClient } from "../../helpers/api_helper";
import { CommanDataTables } from "../../Components/Common/DashboardDataTbale";
import {
  CurrentDate,
  PreviousDate,
} from "../../Components/Common/DefaultDateSet";
import { DatePicker } from "../../Components/Common/Flatpicker";
import TeamSelect from "../../Components/Common/TeamSelect";
import EmployeeSelect from "../../Components/Common/EmployeeSelect";
import { addMonths } from "../../Components/Common/FlatePicker";

const api = new APIClient();

const CallPerformance = ({ columns, headerName, title, sortKey }) => {
  const permissionType = JSON.parse(localStorage.getItem("permission"));
  const viewReportPermission = permissionType?.includes("VIEW_REPORT");
  const loginType = JSON.parse(localStorage.getItem("logintype"));

  const [isLoading, setIsLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [callData, setCallData] = useState([]);
  const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [teamIndu, setTeamIndu] = useState([]);
  const [EmployeeData, setEmployeeData] = useState([]);
  const [query, setQuery] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const previewsDate = PreviousDate();
  const currentDate = CurrentDate();
  const [flag, setFlag] = useState(false);
  const [debouncedSearchText, setDebouncedSearchText] = useState('');

  const [selectedFromDate, setSelectedFromDate] = useState(
    previewsDate + "T00:00:00"
  );

  const [selectedToDate, setSelectedToDate] = useState(
    currentDate + "T23:59:59"
  );

  const formattedStartDate = new Date(selectedFromDate);
  const formattedEndDate = new Date(selectedToDate);

  // Convert formatted startDate and endDate to timestamps
  const startDate = new Date(formattedStartDate).getTime();
  const endDate = new Date(formattedEndDate).getTime();

  const fetchData = async (page, size = perPage, defaultId) => {
    let data = {
      startRange: startDate,
      endRange: endDate,
      filterField: sortKey,
      employeeIds: defaultId,
    };

    setIsLoading(true);
    try {
      const response = await api.create(`${TOP_CALLER_API}`, data);
      if (response.success) {
        const sortedData = response.data.sort(
          (a, b) => b[sortKey] - a[sortKey]
        );
        setCallData(sortedData);
        setTotalRows(sortedData.length);
      } else {
        console.error("Error fetching data:", response.message);
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    try {
      const defaultId =
        selectedEmployeeOptions?.map((option) => option?.id) || [];
      if (defaultId.length > 0 && flag === false) {
        fetchData(currentPage, perPage, defaultId);
        setFlag(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [EmployeeData]);




  // Pagination Handlers
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  const handleFromDateChange = (dates) => {
    const newFromDate = dates[0]; 
    setSelectedFromDate(newFromDate);
    const newToDate = addMonths(newFromDate, 3);
    setSelectedToDate(newToDate);
};

  const handleToDateChange = (dates) => {
    setSelectedToDate(dates[0]);
  };
  const handleSubmit = () => {
    const updatedIds = selectedEmployeeOptions.map((userid) => userid?.id);
    fetchData(currentPage, 10, updatedIds); //perpage
    setCurrentPage(1);
    setPerPage(10);
  };

  const resetInputHistory = () => {
    setSelectedToDate("");
    setSelectedFromDate("");
    setSelectedOptions([]);
    setSelectedEmployeeOptions([]);
    setTeamIndu([]);
  };

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (query.length >= 3) {
        setDebouncedSearchText(query);
      } else {
        setDebouncedSearchText(''); 
      }
    }, 300); 

    return () => {
      clearTimeout(handler); 
    };
  }, [query]);

 
  useEffect(() => {
    const filtered = (callData || []).filter((item) =>
      Object.values(item).some((value) =>
        value?.toString()?.toLowerCase()?.includes(debouncedSearchText.toLowerCase())
      )
    );
    setFilteredData(filtered);
    if (debouncedSearchText) setCurrentPage(1);
  }, [callData, debouncedSearchText]);


  
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={title} pageTitle="Employee Call Metrics" />
          <Card className="h-100">
            <div className="sn-metrics-tble-informtion">
              {" "}
              <span>*</span> <i>{headerName}</i>
            </div>
            <CardBody>
              <Row className="sn-header-select-employess-home sn-team-managment-tble sn-top-inputs-heading">
                <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2">
                  <DatePicker
                    labelContent="From Date"
                    getDate={selectedFromDate}
                    options={{
                      disable: [
                        {
                          from: new Date(),
                        },
                      ],
                      dateFormat: "Y-m-d h:i K",
                      maxDate: new Date(new Date().setDate(new Date().getDate())),
                    }}
                    onChange={handleFromDateChange}


                  />
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xs-12">
                  <DatePicker

                    labelContent="To Date"
                    getDate={selectedToDate}
                    options={{
                      enable: selectedFromDate
                        ? [
                          {
                            from: selectedFromDate,
                            to: addMonths(selectedFromDate, 3),
                          },
                        ]
                        : [
                          {
                            from: new Date(),
                          },
                        ],
                        dateFormat: "Y-m-d h:i K",
                    }}
                    onChange={handleToDateChange}
                  />
                </div>

                {((viewReportPermission && loginType === "employee") ||
                  loginType === "organization") && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xs-12">
                    <TeamSelect
                      setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                      setSelectedOptions={setSelectedOptions}
                      teamIndu={teamIndu}
                      setTeamIndu={setTeamIndu}
                      labelContent="Select Team"
                    />
                  </div>
                )}

                {((viewReportPermission && loginType === "employee") ||
                  loginType === "organization") && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xs-12">
                    <EmployeeSelect
                      EmployeeDefault={EmployeeData}
                      setEmployeeDefault={setEmployeeData}
                      teamIndu={teamIndu}
                      selectedEmployeeOptions={selectedEmployeeOptions}
                      setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                      labelContent="Select Employee"
                    />
                  </div>
                )}

                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xs-12">
                  <FormGroup>
                    <Label for="exampleSelect"></Label>
                    <div
                      id="exampleSelect"
                      className="sn-call-apply-btn-reset mt-2"
                    >
                      <Button
                        id="exampleSelect"
                        type="submit"
                        color="danger"
                        className=""
                        onClick={handleSubmit}
                        disabled={isLoading}
                      >
                        Apply
                      </Button>

                      <Button
                        className=""
                        style={{ marginLeft: "10px" }}
                        id="exampleSelect"
                        outline
                        color="danger"
                        onClick={resetInputHistory}
                        disabled={isLoading}
                      >
                        Reset
                      </Button>
                    </div>
                  </FormGroup>
                </div>
              </Row>
              <Row className="sn-user-tble-manin-manage">
                <Col lg={12} className="salenninja-dashboard-agent-tblemani">
                  <CommanDataTables
                    perPage={perPage}
                    column={columns} // Use dynamic columns
                    tableData={filterData}
                    handlePerRowsChange={handlePerRowsChange}
                    totalRows={totalRows}
                    query={query}
                    handleSearch={handleSearch}
                    currentPage={currentPage}
                    isLoading={isLoading}
                    handlePageChange={handlePageChange}
                    setCurrentPage={setCurrentPage}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CallPerformance;
