import React, { useEffect } from "react";
import { useProfile } from "../Components/Hooks/UserHooks";
import { useNavigate, useLocation } from "react-router-dom";

const AuthProtected = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useProfile();

  useEffect(() => {
    if (!token) {
      const redirectPath = location.pathname === '/admin-dashboard' ? '/admin-login' : '/login';
      navigate(redirectPath, { state: { from: location.pathname }, replace: true });
    }
  }, [token, navigate, location]);

  if (!token) {
    return null;
  }
  
  return <>{children}</>;
};

export { AuthProtected };
