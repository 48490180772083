import axios from "axios";
import { api } from "../config";
// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
//token get
const token = JSON.parse(localStorage.getItem("authUser")) ? JSON.parse(localStorage.getItem("authUser")) : null;
if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}
// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },

  function (error) {
    let message;
    switch (error.response.status) {
      case 401:
        message = "inavlid token";
        localStorage.removeItem("authUser");
        window.location.href = '/login'
        break;
      case 500:
        // window.location.href = '/server-error'
        break;
      default:
        message = error.response.message || error;
    }
    return Promise.reject(error);
  }
);

class APIClient {
  constructor() {
    this.initializeInterceptors();
  }
  initializeInterceptors() {
    axios.interceptors.request.use(
      (config) => {
        const userToken = JSON.parse(localStorage.getItem("authUser"));
        if (userToken) {
          config.headers.Authorization = `Bearer ${userToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  get = (url, params) => {
    let response;
    let paramKeys = [];
    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }
    return response;
  };


  create = (url, data) => {
    return axios.post(url, data);
  };

  
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };

  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
  patch = (url, data) => {
    return axios.patch(url, data);
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, getLoggedinUser };