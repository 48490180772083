import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  FUTURE_SUBSCRIPTION,
  GET_EMPLOYEE,
  POST_GET_INDUSTRY_TYPE,
  POST_PROFILE,
} from "../../helpers/url_helper";
import { APIClient } from "../../helpers/api_helper";
import { formatDate } from "../../Components/Common/FormDateTime";
import moment from "moment";
import {
  DatePickerFutureSub,
  isValidDate,
} from "../../Components/Common/Flatpicker";
import { ToastContainer, toast } from "react-toastify";
import ModalManageUser from "./ModalManageUser";
import { calculateDate } from "../../Components/Common/ToCamelCase";
import { useLocation } from "react-router-dom";
import { useProfileData } from "../../Components/Hooks/UserHooks";
import { getCurrencyExchangeRate } from "../../Components/Common/ApiUtils";
const api = new APIClient();

const FuturePlan = () => {
  const { profileData } = useProfileData();
  const location = useLocation();
  const planType = location?.state?.data;
  const { type, pricePerGB, pricingPerUser } = planType || {};
  const [employeeSelect, setEmployeeSelecteded] = useState([]);
  const [planTypeData, setPlanTypeData] = useState([]);
  const [transactionType, setTransactionType] = useState([]);

  //modal open
  const [numberEmployee, setNumberEmployee] = useState(null);
  const [storage, setStorage] = useState(null);
  const [addTeamModal, setAddTeamModal] = useState(false);

  // date picker state
  const [planExperiDate, setPlanExperiDate] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState([]);
  const [show, setShow] = useState(false);

  const [employeeData, setEmployeeData] = useState([]);


  
  const [amountPerUserInr, setAmountPerUserInr] = useState(null);

  // // flatPicker Date
  const formattedStartDate = formatDate(selectedFromDate);
  const formattedEndtDate = formatDate(selectedEndDate);

  const createTeam = () => {
    setAddTeamModal(true);
  };

  const handleToDateChange = async (dateStr) => {
    const oneMonthBefore = await calculateDate(dateStr);
    setSelectedEndDate(oneMonthBefore);
    setSelectedFromDate(dateStr);
    setShow(true);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.get(`${POST_PROFILE}`);
        if (response.success === true) {
          const apiResponse = response?.data;
          setPlanTypeData(apiResponse?.selectedPlanDetails?.type);
          setPlanExperiDate(apiResponse?.subscriptionEndDate);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserData();
    getCurrencyExchangeRate(setAmountPerUserInr); 
    masterData();
    getEmployeeData();
  }, []);

  const handleInputChange = (e) => {
    const numEmployees = parseInt(e.target.value);
    if (isNaN(numEmployees) || numEmployees <= 0) {
      setNumberEmployee("");
      setStorage("");
      return;
    } else {
      setNumberEmployee(numEmployees);
    }
  };

  const selectHandleChange = (e) => {
    const selectedStorage = parseInt(e.target.value);
    if (isNaN(numberEmployee) || numberEmployee <= 0) {
      toast.error("Please enter a valid number of users", { autoClose: 1000 });
      setNumberEmployee("");
      setStorage("");
      return;
    } else {
      setStorage(selectedStorage);
    }
  };

  const masterData = async () => {
    try {
      const response = await api.get(`${POST_GET_INDUSTRY_TYPE}`);
      if (response.success === true) {
        const res = response?.data?.transactionTypeConstant;
        setTransactionType(res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const futureSubscription = useCallback(async () => {
    if (isNaN(numberEmployee) || numberEmployee <= 0) {
      toast.error("Please enter a valid number of users.", { autoClose: 1000 });
      return;
    }

    if (!isValidDate(new Date(formattedStartDate))) {
      toast.error("Please select a start date.", { autoClose: 1000 });
      return;
    }

    let futplanType = "";
    if (numberEmployee && storage && formattedStartDate) {
      futplanType = transactionType?.FUTURE_SUBSCRIPTION_BOTH;
    } else if (numberEmployee && formattedStartDate) {
      futplanType = transactionType?.FUTURE_SUBSCRIPTION_EMPLOYEE;
    }
    // Call the masterData function
    masterData();
    let data = {
      planType: planTypeData,
      employeeIds: employeeSelect,
      numberOfEmployee: parseInt(numberEmployee),
      storage: parseInt(storage),
      transactionType: futplanType,
      startDate: formattedStartDate,
    };

    try {
      const response = await api.create(`${FUTURE_SUBSCRIPTION}`, data);
      if (response?.success) {
        const res = response?.data?.pGateWayResponse;
        if (res?.order?.url) {
          window.open(res.order.url, "_blank", "noopener,noreferrer");
        } else {
          console.error("URL not found in API response");
        }
      }
    } catch (error) {
      console.error("Error during API call:", error?.response?.data?.message);
      toast.error(error?.response?.data?.message, { autoClose: 1000 });
    }
  }, [
    employeeSelect,
    transactionType,
    numberEmployee,
    storage,
    planTypeData,
    formattedStartDate,
  ]);

  const getEmployeeData = async () => {
    try {
      const response = await api.get(`${GET_EMPLOYEE}`);
      if (response.success === true) {
        setEmployeeData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const totalUsersAmount = parseInt(numberEmployee) * pricingPerUser || 0;
  const totalStorageAmount = parseInt(storage) * pricePerGB || 0;
  const totalAmount = totalUsersAmount + totalStorageAmount;
  const amountinr = totalAmount * amountPerUserInr;

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Future Subscription" pageTitle="Pages" />
          <Row>
            <Col xl={6}>
              <Row>
                <Col xl={12}>
                  <Card style={{ height: "340px", minHeight: "340px" }}>
                    <CardBody>
                      <Row className="d-flex">
                        <Col xs={12}>
                          <Alert
                            color="danger"
                            className="p-2 ml-0 rounded- alert-sm"
                          >
                            <p className="pt-1 d-flex " style={{ margin: "0" }}>
                              Your subscription is currently active and it will
                              continue until{" "}
                              {moment(planExperiDate).format("LL")}
                            </p>
                          </Alert>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={4}>
                          <DatePickerFutureSub
                            incExperiDatePlan={planExperiDate}
                            onChange={handleToDateChange}
                            selectedFromDate={planExperiDate}
                            labelContent="Start Date"
                          />
                        </Col>

                        <Col xl={4}>
                          <Label htmlFor="formCheck1">Number of Users</Label>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder=""
                            value={numberEmployee}
                            onChange={handleInputChange}
                            onWheel={(e) => e.target.blur()}
                          />
                          <Col style={{ marginTop: "5px", fontSize: "13px" }}>
                            Currently you have {employeeData.length} out of{" "}
                            {profileData?.organizationStrength} employee(s).
                          </Col>
                        </Col>

                        <Col xl={4}>
                          <Label htmlFor="formCheck1">Storage</Label>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder=""
                            value={storage}
                            onChange={selectHandleChange}
                            onWheel={(e) => e.target.blur()}
                          />

                          <Col style={{ marginTop: "5px", fontSize: "12px" }}>
                            &nbsp;<span className="mt-2">GB</span>
                            {/* Available Storage space 33.50 GB out of 45 GB. */}
                          </Col>
                        </Col>
                      </Row>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <div className="justify-content-end mb-3">
                          <Col xs="auto">
                            <Button
                              className="rounded btn-sm btn btn-danger"
                              color="danger"
                              onClick={() => createTeam()}
                              style={{
                                padding: "7px 7px 7px 7px",
                                fontSize: "14px",
                              }}
                            >
                              Manage Users
                            </Button>
                          </Col>
                        </div>
                      </div>
                      {show && (
                        <Col style={{ marginTop: "50px" }}>
                          Note :- Your subscription period is{" "}
                          {formattedStartDate} to {formattedEndtDate}
                        </Col>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col xxl={6}>
              <Card className="card-height-100">
                <CardBody className="p-0">
                  <div className="p-3 bg-soft-warning">
                    <div className="float-end ms-2"></div>
                    <h6 className="mb-0 text-danger">Subscription Summary</h6>
                  </div>
                  <div className="p-3">
                    <div className="mt-3 pt-2">
                      <div className="d-flex mb-2">
                        <div className="flex-grow-1">
                          <p className="fs-13 mb-0">Number of Users</p>
                          {numberEmployee && parseInt(numberEmployee) > 0
                            ? `${parseInt(numberEmployee)} × AED ${pricingPerUser}`
                            : "0"}
                        </div>
                        <div className="flex-shrink-0">
                          <h6 className="mb-0">AED {totalUsersAmount}</h6>
                        </div>
                      </div>
                      <div className="d-flex mb-2">
                        <div className="flex-grow-1">
                          <p className="fs-13 mb-0">Call Recording Storage</p>
                          {storage && parseInt(storage) > 0
                            ? `${parseInt(storage)} GB × AED ${pricePerGB}`
                            : "0"}
                        </div>
                        <div className="flex-shrink-0">
                          <h6 className="mb-0">AED {totalStorageAmount}</h6>
                        </div>
                      </div>
                      <div className="d-flex mb-2">
                        <div className="flex-grow-1">
                          <p className="fs-13 mb-0">Total Amount</p>
                        </div>
                        <div className="flex-shrink-0">
                          <h6 className="mb-0">AED {totalAmount}</h6>
                        </div>
                      </div>
                      {totalAmount && (
                        <div className="alert alert-danger" role="alert">
                          The payment will be processed in AED, but for your
                          convenience, the final amount is approximately ₹{" "}
                          {parseInt(amountinr)} based on current rates
                        </div>
                      )}
                    </div>
                    <div className="mt-3 pt-2">
                      <Button
                        className="btn btn-danger w-100"
                        onClick={futureSubscription}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <ModalManageUser
              setEmployeeSelecteded={setEmployeeSelecteded}
              addTeamModal={addTeamModal}
              setAddTeamModal={setAddTeamModal}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FuturePlan;
