export const formatToISOString = (date) => {
    return date ? date.toISOString() : '';
};

export const formatTime = (seconds) => {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = seconds % 60;

    return hours + "h " + minutes + "m " + remainingSeconds + "s";
}

export const formatDuration = (duration) => {
    const seconds = Math.floor(duration % 60);
    const minutes = Math.floor((duration / 60) % 60);
    const hours = Math.floor(duration / 3600);
    return [hours, minutes, seconds].map(v => String(v).padStart(2, '0')).join(':');
  };



  export const addMonths = (date, months) => {
    const newDate = new Date(date); 
    newDate.setMonth(newDate.getMonth() + months); 
    newDate.setHours(23, 59, 59, 0); 
    return newDate;
  };
  
