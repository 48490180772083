import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Col, Input, Row } from "reactstrap";
import Loader from "./Loader";


const tableCustomStyle = {
    table: {
        style: {
            height: "500px",
        },
    },
    headCells: {
        style: {
            width: "130px",
            justifyContent: "center",
            // backgroundColor: '#dbdde0',
            fontWeight: "700",
            fontSize: "small",
            fontFamily: "Arial, Helvetica, sans-serif",
            background: "red",
        },
    },
    cells: {
        style: {
            fontWeight: "500",
            width: "150px",
            fontSize: "small",
            justifyContent: "center",
            fontFamily: "Arial, Helvetica, sans-serif",
        },
    },
    headRow: {
        style: {
            borderTop: "1px solid #ddd",
            background: "#fff2f2",
            fontWeight: "600",
            borderRight: "1px solid #ddd",
        },
    },
    headCells: {
        style: {
            borderRight: "1px solid #fff2f2",
            borderLeft: "1px solid #ddd",
            borderBottom: "1px solid #fff2f2",
        },
    },

    cells: {
        style: {
            borderRight: "1px solid #fff",
            borderLeft: "1px solid #ddd",
            "&:first-child": {
                borderLeft: "1px solid #ddd",
            },
            "&:last-child": {
                borderRight: "1px solid #ddd",
            },
        },
    },
};

const commantableCustomStyle = {
    table: {
        style: {
            height: "750px",
        },
    },
    headCells: {
        style: {
            width: "130px",
            justifyContent: "center",
            // backgroundColor: '#dbdde0',
            fontWeight: "700",
            fontSize: "small",
            fontFamily: "Arial, Helvetica, sans-serif",
            background: "red",
        },
    },
    cells: {
        style: {
            fontWeight: "500",
            width: "150px",
            fontSize: "small",
            justifyContent: "center",
            fontFamily: "Arial, Helvetica, sans-serif",
        },
    },
    headRow: {
        style: {
            borderTop: "1px solid #ddd",
            background: "#fff2f2",
            fontWeight: "600",
            borderRight: "1px solid #ddd",
        },
    },
    headCells: {
        style: {
            borderRight: "1px solid #fff2f2",
            borderLeft: "1px solid #ddd",
            borderBottom: "1px solid #fff2f2",
        },
    },

    cells: {
        style: {
            borderRight: "1px solid #fff",
            borderLeft: "1px solid #ddd",
            "&:first-child": {
                borderLeft: "1px solid #ddd",
            },
            "&:last-child": {
                borderRight: "1px solid #ddd",
            },
        },
    },
};
const DashboardDataTable = ({ column, tableData, currentPage }) => {
    const paginationOptions = [10, 25, 50, 100];
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows per page:',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
        rowsPerPageOptions: [10, -1], // -1 represents "All"
    };
    // State to track a unique key for the DataTable
    const [tableKey, setTableKey] = useState(0);

    useEffect(() => {
        // Update the key whenever tableData changes to reset pagination
        setTableKey((prevKey) => prevKey + 1);
    }, [tableData, currentPage]);

    return (
        <Row className="n-tble-home-page">
            <Col lg={12}>
                <DataTable
                    key={tableKey} // Force re-render when data changes
                    customStyles={tableCustomStyle}
                    columns={column}
                    data={tableData}
                    pagination
                    paginationComponentOptions={paginationComponentOptions} // Always show [10, 25, 50, 100]
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    paginationPerPage={10} // Start with 10 rows per page
                />
            </Col>
        </Row>
    );
};

const CommanDataTables = ({ column, tableData, searchText, inputClass, handleSearch, isLoading ,currentPage}) => {
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows per page:',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
        rowsPerPageOptions: [10, -1], // -1 represents "All"
    };

    const [tableKey, setTableKey] = useState(0);

    useEffect(() => {
        // Update the key whenever tableData changes to reset pagination
        setTableKey((prevKey) => prevKey + 1);
    }, [tableData, currentPage]);
    return (
        <>
            {/* <div className='custom-datatable'>
                <Row className='g-3 mt-0'>
                    <Col xxl={2} md={4} className='ms-auto'>
                        <Input
                            value={searchText}
                            type="text"
                            className={`form-control ${inputClass}`}
                            id="exampleInputrounded"
                            placeholder="Search by keyword..."
                            onChange={handleSearch}
                        />
                    </Col>
                    <Col lg={12} className='sn-tble-call-archive-mainn sn-team-number--employyes-btn sn-tble-bottom-scroll-main sn-total-calltype-btn-status sn-employee-managment-tble-detail' style={{ textAlign: 'center', overflow: 'scroll' }}>

                        <DataTable
                            className='sn-managment-team-scroll-tble sn-tble-droup-down-edit-delete'
                            customStyles={commantableCustomStyle}
                            columns={column}
                            data={tableData}
                            fixedHeaderScrollHeight={fixedHeaderScrollHeights ? fixedHeaderScrollHeights : ''}
                            fixedHeader
                            striped
                            search={true}
                            pagination={true}
                            paginationServer={true}
                            noDataComponent={<div className="center-content">There are no records to display</div>}
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            responsive={true}
                            responsiveSm={true}
                            responsiveMd={true}
                            responsiveLg={true}
                            responsiveXl={true}
                            progressPending={isLoading}
                            progressComponent={<Loader />}
                        />
                    </Col>
                </Row>
            </div> */}

            <div className='custom-datatable'>
                <Row className='g-3 mt-0'>
                    <Col xxl={2} md={4} className='ms-auto'>
                        <Input
                            value={searchText}
                            type="text"
                            className={`form-control ${inputClass}`}
                            id="exampleInputrounded"
                            placeholder="Search by keyword..."
                            onChange={handleSearch}
                        />
                    </Col>
                    <Col lg={12} className='sn-tble-call-archive-mainn sn-team-number--employyes-btn sn-tble-bottom-scroll-main sn-total-calltype-btn-status sn-employee-managment-tble-detail' style={{ textAlign: 'center', overflow: 'scroll' }}>
                        <Col lg={12}>
                            <DataTable
                                 key={tableKey}
                                paginationComponentOptions={paginationComponentOptions}
                                progressPending={isLoading}
                                progressComponent={<Loader />}
                                fixedHeader
                                customStyles={commantableCustomStyle}
                                columns={column}
                                data={tableData}
                                pagination
                                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            />
                        </Col>

                        {/* <DataTable
                            className='sn-managment-team-scroll-tble sn-tble-droup-down-edit-delete'
                            customStyles={commantableCustomStyle}
                            columns={column}
                            data={tableData}
                            fixedHeaderScrollHeight={fixedHeaderScrollHeights ? fixedHeaderScrollHeights : ''}
                            fixedHeader
                            striped
                            search={true}
                            pagination={true}
                            paginationServer={true}
                            noDataComponent={<div className="center-content">There are no records to display</div>}
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            responsive={true}
                            responsiveSm={true}
                            responsiveMd={true}
                            responsiveLg={true}
                            responsiveXl={true}
                            progressPending={isLoading}
                            progressComponent={<Loader />}
                        /> */}
                    </Col>
                </Row>
            </div>

        </>
    );
};



export { DashboardDataTable, CommanDataTables }



